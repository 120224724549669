.megamenu.whatwedo .MuiPopover-paper {
    width: 100% !important;
}

.megamenu .MuiPopover-paper {
    border-radius: 8px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 20px 30px !important;
    padding: 5px 13px !important;
    right: 7px !important;
    top: 51px !important;
    max-height: calc(100% - 60px) !important;
}

.megamenu .flx_fs {
    align-items: center !important;
}

.megamenu .flx_fs .MuiSvgIcon-root {
    margin-right: 10px !important;
}

.tabpanel_headsecton {
    border-bottom: solid 1px #4d4f5d !important;
    width: 100% !important;
}

.w100 {
    width: 100% !important;
}

.megamenu .two {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 40px !important;
}

.megamenu .thr {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 54px !important;
}

.megamenu .menus {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #4d4f5d !important;
}

.megamenu .Tabssidebx .menus {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #1930ab !important;
}

.megamenu .Tabssidebx .menus:hover {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #00b0f0 !important;
}

.megamenu .submenus {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #00b0f0 !important;
}

.megamenu .menus:hover {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #00b0f0 !important;
    text-decoration: underline !important;
}


.Tabssidebx {
    width: 100% !important;
    background-color: #f8f8f8 !important;
    border-radius: 6px !important;
    padding: 12px !important;
    height: 98% !important;
    padding-top: 56px !important;
}

.Tabssidebx h6 {
    font-size: 13px !important;
    line-height: 16px !important;
}

.vertical-carosol {
    background-color: #fff;
    border: solid 1px rgba(0, 0, 0, 0.12) !important;
    border-radius: 6px !important;
    padding: 12px !important;
}

/* tabs */

.MuiTabs-indicator {
    display: none !important;
}

.megamenu .MuiTab-root {
    background: var(--white) !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    color: #4d4f5d !important;
    min-height: auto !important;
    text-align: left !important;
    -webkit-flex-direction: initial !important;
    flex-direction: column !important;
    display: flex !important;
    justify-content: left !important;
    align-items: self-start !important;
    max-width: 100% !important;
    padding: 6px 16px;
    margin: 4px 12px 4px 0px !important;
}

.megamenu .MuiTab-root.Mui-selected {
    background-color: #1930ab !important;
    color: #fff !important;
}

.megamenu .MuiTabScrollButton-root.Mui-disabled {
    display: none !important;
}

.megamenu-tabs {
    border-right: 1px solid #e0e0e0 !important;
}

.tab-label {
    margin: 0px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

/* ======== */
@keyframes verticalScroll {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-83%);
    }

    100% {
        transform: translateY(0%);
    }
}

.vertical-scroll {
    height: 130px !important;
    overflow: hidden !important;
    position: relative;
}

.scroll-content {
    animation: verticalScroll 30s linear infinite;
    display: flex;
    flex-direction: column;
}

.inner-card {
    text-align: center !important;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    padding: 6px !important;
    margin: 10px !important;
    border-radius: 8px !important;


}

.inner-card .bg-img {
    /* height: 60px !important; */
    width: 100% !important;
    border-radius: 8px !important;
    height: 80px !important;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
}

.inner-card .fs11 {
    padding-top: 8px !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    text-align: left !important;
    margin-bottom: 6px !important;
}

.vertical-carosol h6 {
    font-size: 13px !important;
    line-height: 19px !important;
}


.MuiTab-root img {
    /* height: 22px !important; */
    width: 24px !important;
    margin-right: 10px !important;
}

.wico {
    display: none !important;
}

.Mui-selected .bico {
    display: none !important;
}

.Mui-selected .wico {
    display: inline !important;
}

.megamenu .vertical_divider {
    border-right: solid 1px #e0e0e0 !important;
    height: 100% !important;
}

.tabsheading {
    color: #8c8c8c !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    letter-spacing: 2px !important;
    margin-bottom: 0px !important;
    line-height: 15px !important;
    margin-left: 17px !important;
    text-transform: capitalize !important;
}

/* ==============mobile view=========== */


.megamenu .bn {
    border: none !important;
}

.megamenu .linkitem span {
    padding-bottom: 5px !important;
    font-size: 12px !important;
    text-decoration: none !important;
    color: #4d4f5d !important;
    position: relative;
    font-weight: 500 !important;
    line-height: 20px;
    letter-spacing: 0px;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.megamenu .linkitem:hover span {
    color: #00b0f0 !important;
    text-decoration: underline !important;
}

.megamenu .bddp0 {
    border-bottom: none !important;
}

@media (max-width: 959px) {

    .megamenu .vertical_divider {
        border: none !important;
    }

}