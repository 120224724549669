.outsourcesbabanner {
  background-position: bottom !important;
  display: grid !important;
  height: 80vh;
  place-items: center !important;
  text-align: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative !important;
}

.text_animated {
  animation-name: zoomIn;
  animation-duration: 2.5s;
  animation-fill-mode: both;
}

.sba_bannerheading {
  font-size: 40px !important;
  line-height: 50px !important;
  font-weight: 300 !important;
}

/* .particalbottomimg {
  position: absolute;
  left: 0px !important;
  bottom: 0px !important;
  width: 100% !important;
} */

/* =============== */

/* =============== */
.sbapgeform .MuiInput-underline::before {
  border-bottom: none !important;
}

.sbapgeform .MuiInput-underline::after {
  border-bottom: none !important;
}

.sbapgeform .sbabanner_textfieldl .MuiInputBase-input {
  padding: 25px !important;
  background-color: #fff !important;
  border-top-left-radius: 1000px !important;
  border-bottom-left-radius: 1000px !important;
}

.sbapgeform .sbabanner_textfield .MuiInputBase-input {
  padding: 25px !important;
  background-color: #fff !important;
}

.bannerbxsba {
  background: rgba(255, 255, 255, 0.28) !important;
  padding: 10px !important;
  border-radius: 900px !important;
}

.sbabannerbtn {
  height: 70px !important;
  padding: 25px !important;
  background-color: #00b0f0 !important;
  border-top-right-radius: 1000px !important;
  border-bottom-right-radius: 1000px !important;
  color: #fff !important;
  font-size: 18px !important;
}

/* =============== */

.sba_icon {
  width: 40px !important;
}

.sba_iconbx {
  height: 60px !important;
  width: 60px !important;
  display: grid !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #1930ab0f !important;
  border-radius: 4px !important;
}

.sbalenders_benefitbx {
  padding: 15px !important;
  border-radius: 8px !important;
  transition: all 0.3s ease-in-out !important;
  min-height: 257px !important;
}

.sbalenders_benefitbx:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.3s ease-in-out !important;
}

#outsourcesba .section {
  padding: 3% 0px;
  padding-bottom: 0px !important;
}

#outsourcesba .sbapgeform::placeholder {
  font-size: 13px !important;
}

#outsourcesba .section2 {
  padding: 3% 0px;
  padding-bottom: 3% !important;
}

#outsourcesba .topbanner2 {
  max-width: 100% !important;
  border-radius: 8px !important;
}

.loan_underwritingcardbx {
  background-color: #46549f14 !important;
  padding: 20px !important;
  min-height: 370px !important;
  transition: all 0.3s ease-in-out !important;
}

.subpoints_sba {
  font-size: 15px !important;
  line-height: 20px !important;
  top: 3px !important;
  position: relative !important;
}

.loan_underwritingcardbx h1 {
  font-size: 70px !important;
  line-height: 80px !important;
}

.comprehensive_heading {
  font-size: 24px !important;
  line-height: 30px !important;
  margin-bottom: 12px !important;
}

.services_heading {
  margin: 2% 0px !important;
  font-size: 34px !important;
}

.black2 {
  color: #7f7f7f2e !important;
}

.loan_underwritingcardbx:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.3s ease-in-out !important;
}

@media (max-width: 960px) {
  .sbapgeform .sbabanner_textfieldl .MuiInputBase-input {
    border-radius: 0px !important;
  }

  .sbabannerbtn {
    border-radius: 0px !important;
  }

  .bannerbxsba {
    background-color: transparent !important;
  }

  .sba_bannerheading {
    font-size: 26px !important;
    line-height: 32px !important;
    font-weight: 300 !important;
  }

  .services_heading {
    font-size: 24px !important;
    line-height: 30px !important;
  }

  .subpoints_sba {
    font-size: 13px !important;
  }

  .loan_underwritingcardbx .two{
    min-height: auto !important;
  } 

  
} 

@media (max-width: 767px) {

  .loan_underwritingcardbx h1{
    font-size: 45px  !important;
    line-height: 55px !important;
  }

}

@media (max-width: 600px) {

  .comprehensive_heading{
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .loan_underwritingcardbx {
    min-height: auto !important;
  }

  .sbalenders_benefitbx{
    min-height: auto !important;
  } 

  .sbapgeform .sbabanner_textfieldl .MuiInputBase-input {
    padding: 9px !important;
  }

  .sbapgeform .sbabanner_textfield .MuiInputBase-input {
    padding: 9px !important;
  }

  .sbabannerbtn {
    height: 34px !important;
    padding: 9px !important;
    font-size: 14px !important;
    text-align: left !important;
  } 

  .sbapgeform .sbabanner_textfieldl .MuiInputBase-input::placeholder{
    font-size: 12px !important;
  }

  .sbapgeform .sbabanner_textfield .MuiInputBase-input::placeholder{
    font-size: 12px !important;
  }
  
}

.sbapgeform .MuiFormHelperText-root {
  position: absolute;
  bottom: 0px;
  font-size: 11px !important;
  right: 6px;
}


.MuiAutocomplete-option{
font-size: 13px !important;
}

.sba-header .MuiButton-root{
  margin-right: 50px !important;
}



/* submit page */ 

.sbasubmit-banner {
  background-position: bottom !important;
  display: grid !important;
  height: calc(100vh - 198px) !important;
  place-items: center !important;
  text-align: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative !important;
}


.sba_dpa_logo{
  width: 300px !important;
}
 
@media (max-width: 600px) {
 .sba_dpa_logo{
    width: 200px !important;
  }
 
} 