/* --------------------------------------16/01/2024 new_banner--------------------------------------- */
.banner_bx h1 {
    font-size: 32px !important;
    line-height: 44px !important;
    font-weight: 500 !important;
    font-family: "Poppins", sans-serif !important;
    text-transform: capitalize !important;
}

.banner_bx h4 {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    text-transform: capitalize;
}

.banner_bx .banner_img {
    height: 240px !important;
    width: 100% !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-color: #1930abd9 !important;
}

/* .banner_bx .img_over {
    width: 100% !important;
    height: 100% !important;
    display: grid !important;
    place-items: start !important;
    justify-content: center !important;
    padding-top: 135px !important;
} */
.banner_bx .img_over {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-content: center !important;
    align-items: center !important;
    justify-content: center !important;
    padding-top: 40px !important;
}

.banner_bx .bl_overlay {
    background-color: #1930abd9 !important;
}

.banner_bx .subtitle {
    margin-top: 8px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 1.3 !important;
}

.banner_bx .dpabtn_sm1 {
    background-color: #00b0f0 !important;
    color: #fff !important;
    border-radius: 50px !important;
    padding: 6px 20px !important;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: none !important;
}

.banner_bx .header_pulse_button {
    position: absolute;
    width: 35px;
    height: 35px;
    border: none;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    background-color: #1930ab;
    background-image: url(../images/aboutus/videoic.webp) !important;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-animation: wpulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: wpulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: wpulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: wpulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.banner_bx .header_pulse_button:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
}

@-webkit-keyframes wpulse {
    to {
        box-shadow: 0 0 0 25px rgba(255, 255, 255, 0);
    }
}

@-moz-keyframes wpulse {
    to {
        box-shadow: 0 0 0 25px rgba(255, 255, 255, 0);
    }
}

@-ms-keyframes wpulse {
    to {
        box-shadow: 0 0 0 25px rgba(255, 255, 255, 0);
    }
}

@keyframes wpulse {
    to {
        box-shadow: 0 0 0 25px rgba(255, 255, 255, 0);
    }
}

.banner_bx .banner_btns {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    margin-top: 12px !important;
}

.banner_bx .banner_pulse {
    display: flex !important;
    justify-content: center !important;
    margin-left: 20px !important;
}

.banner_bx .banner_btns .cta_btn {
    margin-right: 20px !important;
}

.banner_bx .banner_btns .bt_button {
    margin-right: 20px !important;
}


.structure_card .mnhg1 {
	min-height: 210px;
}

@media (max-width:767px) {
    .banner_bx h1 {
        font-size: 26px !important;
        line-height: 34px !important;
    }

    .banner_bx h4 {
        font-size: 13px !important;
        line-height: 20px !important;
    }

    .banner_bx .banner_img {
        height: 215px !important;
    }

    .banner_bx .dpabtn_sm1 {
        padding: 3px 20px !important;
        font-size: 11px !important;
    }

    .banner_bx .header_pulse_button {
        width: 25px !important;
        height: 25px !important;
    }
}

@media (max-width:599px) {
    .banner_bx .banner_btns {
        display: block !important;
    }

    .banner_bx .banner_pulse {
        margin-top: 8px !important;
        margin-left: 0px !important;
    }

    .banner_bx .banner_btns .cta_btn {
        margin-right: 0px !important;
    }

    .banner_bx .banner_btns .bt_button {
        margin-right: 0px !important;
    }
    .banner_bx .img_over {
        padding-top: 16px !important;
    }
    .banner_bx h1 {
        font-size: 22px !important;
        line-height: 28px !important;
    }

    .banner_bx h4 {
        font-size: 11px !important;
        line-height: 16px !important;
        margin-top: 0px !important;
    }

}

@media (min-width:600px) {
    .banner_bx .cta_btn {
        margin-top: -14px !important;
    }
}

@media (max-width:399px) {
    .banner_bx .subtitle {
        font-size: 11px !important;
    }
}