.alltxfieldlg .MuiFilledInput-root {
  border-radius: 4px;
  position: relative;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #ebf2fc;
}

.alltxfieldlg .MuiFilledInput-root.MuiFilledInput-underline::before {
  border-bottom: 1px solid #dadcda;
}

.alltxfieldlg .MuiFormLabel-root {
  font-size: 12px !important;
}

.alltxfieldlg .MuiInputBase-root {
  font-size: 12px !important;
  color: #000 !important;
  background-color: transparent !important;
  text-align: left !important;
}

.success_story.alltxfieldlg .MuiInputBase-root {
  font-size: 13px !important;
  padding: 4px 9px !important;
} 

.success_story .MuiAutocomplete-option{
  font-size: 13px !important;
  padding: 4px 12px !important;
} 

.sticky_top_case_study{
  background-color: #ebf2fc !important;
  font-size: 20px;
  padding: 20px 0;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 60px !important;
  z-index: 999 !important;
}
/* .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #1930ab !important;
  border-width: 2px !important;
} */

#sbacontactus .alltxfieldlg .MuiInputBase-root {
  font-size: 15px !important;
  color: #000 !important;
  background-color: transparent !important;
  text-align: left !important;
}

.alltxfieldlg .MuiFormHelperText-contained {
  margin: 0px !important;
  /* color: #1930ab; */
}

.alltxfieldlg .MuiFilledInput-input {
  font-size: 14px !important;
}

.alltxfieldlg .MuiInputLabel-filled {
  z-index: 1;
  transform: translate(13px, 18px) scale(1);
  pointer-events: none;
}

.alltxfieldlg .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(18px, 6px) scale(0.75) !important;
  font-size: 13px !important;
  color: #1930ab;
}

.alltxfieldlga .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 5px) scale(0.75) !important;
  font-size: 13px !important;
  color: #1930ab;
  display: block !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  /* -webkit-text-fill-color: initial; */
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

@media (max-width: 600px) {
  .success_story.alltxfieldlg .MuiInputBase-root {
    font-size: 13px !important;
  }
}