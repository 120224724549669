/* .social-icons>.MuiIconButton-root:nth-child(1) {
    background-color: #fff !important;
    color: blue !important;
}

.social-icons>.MuiIconButton-root:nth-child(2) {
    background-color: #fff !important;
    color: #00b0f0 !important;
}

.social-icons>.MuiIconButton-root:nth-child(3) {
    background-color: #fff !important;
    color: rgb(166, 0, 144) !important;
}

.social-icons>.MuiIconButton-root:nth-child(4) {
    background-color: #fff !important;
    color: red !important;
}

.social-icons>.MuiIconButton-root:nth-child(5) {
    background-color: #fff !important;
    color: blue !important;
}

.social-icons>.MuiIconButton-root:nth-child(6) {
    background-color: #fff !important;
    color: blue !important;
} */

.social-icons .linkedin-icon {
  background-color: #fff !important;
  color: #0000ff !important;
}

.social-icons .twitter-icon {
  background-color: #fff !important;
  color: #00b0f0 !important;
}

.social-icons .instagram-icon {
  background-color: #fff !important;
  color: rgb(166, 0, 144) !important;
}

.social-icons .youtube-icon {
  background-color: #fff !important;
  color: red !important;
}

.social-icons .facebook-icon {
  background-color: #fff !important;
  color: #0000ff !important;
}

.social-icons .threads-icon {
  background-color: #fff !important;
  color: #0000ff !important;
}

.social-icons .MuiIconButton-root:hover {
  transform: translate(0, -6px) !important;
}

.footer {
  padding: 2% 0px !important;
  padding-bottom: 0px !important;
}

.social-icons .MuiIconButton-root {
  margin: 5px 0px !important;
  margin-right: 12px !important;
  padding: 8px !important;
  transition: all 0.3s ease-in-out;
}

.social-icons .MuiIconButton-root img {
  height: 18px !important;
  width: 18px !important;
}

.foot_logo {
  /* width: 30% !important;
    min-width: 250px !important;
    margin: auto !important; */
  max-width: 175px !important;
  margin: auto !important;
}

.newfooterlink .v_div_line {
  margin: 0px 6px !important;
  color: #4d4f5d !important;
  align-items: center;
  display: flex;
  margin-bottom: 4px !important;
}

.txt_btn {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  background-color: transparent !important;
  color: #4d4f5d !important;
}

.txt_btn:hover {
  background-color: transparent !important;
  color: #1930ab !important;
}

.footer-base {
  padding: 20px 0px !important;
  padding-bottom: 10px !important;
}

.footer-base h6 {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}

.footer-base .txt_btn {
  font-weight: 400 !important;
}

.newfooterlink {
  display: flex !important;
  list-style-type: none;
  padding: 0;
  margin: auto !important;
  justify-content: center !important;
}

.newfooterlink .f_li:hover {
  color: #1930ab !important;
}

.newfooterlink .f_li {
  align-items: center !important;
  display: flex !important;
  margin: 0px 0px !important;
  font-size: 16px !important;
  color: #4d4f5d !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  background-color: transparent !important;
  text-transform: capitalize !important;
  padding: 0px !important;
  text-align: left !important;
}

@media (max-width: 600px) {
  .v_div_line {
    display: none !important;
  }

  .newfooterlink {
    display: block !important;
    text-align: start !important;
    justify-content: flex-start !important;
  }

  .txt_btn {
    font-size: 13px !important;
    line-height: 22px !important;
    width: 30% !important;
    text-align: start !important;
    justify-content: flex-start !important;
  }

  /* .list_view {
        height: 6px !important;
        width: 6px !important;
        background-color: #4d4f5d !important;
        border-radius: 50% !important;
        margin-right: 5px !important;
    } */

  .footer-base .txt_btn {
    width: 100% !important;
    text-align: center !important;
    justify-content: center !important;
  }

  .footer-base h6 {
    font-size: 13px !important;
    line-height: 20px !important;
  }
}

@media (max-width: 500px) {
  .txt_btn {
    width: 50% !important;
  }
}

@media (max-width: 400px) {
  .mob_block {
    display: block !important;
  }
}
