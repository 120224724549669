/* @import "./asset/css/react_carousel.css"; */
/* @import "./asset/css/textfield.css"; */
/* @import "./asset/css/primary_tabbed.css"; */
/* @import "./asset/css/smartpipe.css"; */

/* @import url(Menu.css); */

@import url(asset/css/cookie_tracker.css);
@import url(asset/css/megamenu.css);
@import url(asset/css/new.css);
@import url(asset/css/new_footer.css);
@import url(asset/css/oracle.css);
@import url(asset/css/CRM.css);
@import url(asset/css/outsourcesba.css);
/* @import url(asset/css/Pentagon.css); */
@import url(asset/css/primary_tabbed.css);
@import url(asset/css/react_carousel.css);
@import url(asset/css/smartpipe.css);
@import url(asset/css/textfield.css);
@import url(asset/css/employee_profile.css);
@import url(asset/css/investor_relations.css);
@import url(asset/css/seo_changes.css);

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none !important;
  color: #1930ab;
  outline: none !important;
}

a:focus {
  text-decoration: none !important;
  color: #1930ab;
  outline: none !important;
}

body {
  background-color: #fff !important;
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

.white {
  color: #fff !important;
}

.blue {
  color: #1930ab !important;
}

.black {
  color: #4d4f5d !important;
}

.black2 {
  color: #7f7f7f !important;
}

.black3 {
  color: #000 !important;
}

.skblue {
  color: #01b4f0 !important;
}

h1 {
  font-size: 56px !important;
  line-height: 64px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
}

.newh1 {
  font-size: 30px !important;
  line-height: 42px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
  margin-bottom: 25px !important;
}

h2 {
  font-size: 30px !important;
  line-height: 42px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
  margin-bottom: 25px !important;
}

.h2v2 {
  font-size: 22px !important;
  line-height: 32px !important;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
}

h2:first-letter {
  text-transform: uppercase;
}

h3 {
  font-size: 20px !important;
  line-height: 26px !important;
  font-weight: 700 !important;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 6px !important;
  text-transform: uppercase;
}

h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  margin: 5px 0px !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
}

h4:first-letter {
  text-transform: uppercase;
}

h5 {
  font-size: 22px !important;
  line-height: 32px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}

h6 {
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 400 !important;
  margin-bottom: 14px !important;
}

.sm_text {
  font-size: 13px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
}

.sm_text2 {
  font-size: 15px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
}

h6 ul {
  padding-left: 16px !important;
}

h6 ol {
  padding-left: 16px !important;
}

/* Specific page font size */
#mainpg h2 {
  font-size: 42px !important;
  line-height: 53px !important;
  font-weight: 300 !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
  margin-bottom: 25px !important;
}

#mainpg .h2v2 {
  font-size: 30px !important;
  line-height: 42px !important;
  font-weight: 300 !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
}

/* #mainpg h5 {
	font-size: 32px !important;
	line-height: 44px !important;
	font-weight: 600 !important;
	font-family: "Poppins", sans-serif !important;
} */

.upp {
  text-transform: uppercase;
}

.subhead {
  font-size: 20px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 4px !important;
  text-transform: uppercase;
  margin: 15px 0px !important;
}

.mainhead {
  font-size: 30px !important;
  line-height: 42px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
  margin-bottom: 20px !important;
}

.mainsmhead {
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 500 !important;
  margin: 5px 0px !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
  margin-bottom: 20px !important;
}

.bdr {
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}

.al_right {
  text-align: right;
}

.al_left {
  text-align: left;
}

.al_center {
  text-align: center;
}

.flx_fs {
  display: flex !important;
  justify-content: flex-start !important;
}

.flx_sb {
  display: flex !important;
  justify-content: space-between !important;
}

.flx_fe {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}


.flx_ar {
  display: flex !important;
  justify-content: space-around !important;
}

.flx_center {
  display: flex !important;
  justify-content: center !important;
}

.fx_c {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 6px !important;
}

.fw4 {
  font-weight: 400 !important;
}

.fw5 {
  font-weight: 500 !important;
}

.fw6 {
  font-weight: 600 !important;
}

.fw7 {
  font-weight: 700 !important;
}

.t-none {
  text-transform: none !important;
}

.div1 {
  margin: 18px 0px !important;
}

.mainsection {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sec_headingtxt {
  color: #373737;
  font-size: 2.8vw !important;
  line-height: 3.8vw !important;
  font-weight: 200 !important;
  font-family: "Poppins", sans-serif !important;
}

.topbanner {
  max-width: 88%;
  height: auto;
  padding: 0;
  margin: 0;
}

.topbanner1 {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.topbanner2 {
  max-width: 90%;
  height: auto;
  padding: 0;
  margin: 0;
}

.topbanner3 {
  max-width: 50%;
  height: auto;
  padding: 0;
  margin: 0;
  margin-right: 10px !important;
  max-height: 320px !important;
  float: left !important;
}

.topbanner4 {
  max-width: 70%;
  height: auto;
  padding: 0;
  margin: 0;
}

.brd8 {
  border-radius: 8px !important;
}

.topbanner5 {
  max-width: 50%;
  height: auto;
  padding: 0;
  margin: 0;
}



.js_center {
  justify-content: center !important;
}

.flex {
  display: flex !important;
}

.jst {
  text-align: justify !important;
}

.error-text {
  color: red;
}

.section {
  padding: 4% 0px;
  /* padding-bottom: 3% !important; */
}

.section2 {
  padding: 4% 0px;
}

.sec_bg {
  background-color: #ebf2fc !important;
}

.w_bg {
  background-color: #ffffff !important;
}

.dpa_caro .item .slideimg {
  max-width: 88%;
  height: auto;
  padding: 0;
  margin: 0;
  margin: 0 auto !important;
}

.whydecimal {
  background: #ebf2fc;
  text-align: center;
  min-height: 350px !important;
  /* margin-bottom: 20%; */
}

.whydecimal .owl-carousel {
  margin-top: 40px;
  margin-bottom: 10%;
}

.whydecimal .owl-item>div {
  position: relative;
  padding: 2rem 1rem;
  background: #fff;
  color: #fff;
  text-align: center;
  -webkit-transition: all 0.5s ease 0.1s;
  -o-transition: all 0.5s ease 0.1s;
  transition: all 0.5s ease 0.1s;
  z-index: 2;
  overflow: hidden;
  margin-top: 25px;
}

.whydecimal .owl-item>div::before {
  content: "";
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  background: -webkit-linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(31%, #00e4ce),
      color-stop(69%, #00abc9));
  background: -webkit-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
  background: -o-linear-gradient(left, #00e4ce 31%, #00abc9 69%);
  background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}

.whydecimal .owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
}

.whydecimal .owl-item.center>div {
  background: #01b4f0;
  padding: 4.5rem 1rem;
  margin-top: -25px;
}

.whydecimal .owl-item.center>div .black {
  color: #fff !important;
}

.whydecimal .owl-item.center>div h4 {
  color: #fff !important;
}

.whydecimal .caroic {
  width: 100px !important;
  margin: 0 auto;
  margin-bottom: 23px;
}

#corevalue {
  /* margin-top: 15%; */
  height: 100%;
  background-color: #fff;
  padding: 0px;
}

#left {
  height: 100%;
  background-color: #1930ab;
  background-image: url(asset/images/index/1.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 6%;
  align-content: center;
  align-items: center;
  display: flex;
}

#red {
  height: 100%;
  background: #ebf2fc;
  padding: 6%;
  align-content: center;
  align-items: center;
  display: flex;
}

.h {
  height: 100%;
}

.rowfullheight {
  width: 100% !important;
  padding: 0px !important;
  height: 75vh !important;
}

.fullcontainer {
  width: 100% !important;
  padding: 0px !important;
  height: 75vh !important;
}

.corvaluebx {
  padding: 14% 20px;
}

.corvaluebx .caroic {
  width: 100px !important;
  margin-bottom: 23px;
}

.stiky {
  position: sticky;
  top: 60px;
  background-color: #1930ab;
  padding: 20px 0px;
  font-size: 20px;
  z-index: +99 !important;
}

.stiky2 {
  position: sticky !important;
  top: 60px !important;
  background-color: #163E64 !important;
  padding: 12px 0px !important;
  font-size: 20px !important;
  z-index: +99 !important;
}

.stiky3 {
  position: sticky !important;
  top: 60px !important;
  background-color: #f2f2f2 !important;
  padding: 12px 0px !important;
  font-size: 20px !important;
  z-index: +99 !important;
}

.sticky_top {
  position: sticky;
  top: 60px;
  padding: 20px 0px !important;
  background-color: #ebf2fc !important;
  z-index: +999 !important;
  border-bottom: 4px solid #1930ab !important;
}

.location {
  top: 61px;
  padding: 22px 0px;
  font-size: 20px;
}

.MuiBackdrop-root {
  background-color: #ffffffeb !important;
}

.ourknowledge .productknow img {
  width: 100px !important;
  margin: 0 auto;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.ourknowledge .productknow img:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

ul#proskills {
  min-width: 100%;
  list-style: outside none none;
  text-align: center !important;
  margin: 0px;
  padding: 0px;
}

ul#proskills li {
  display: inline-block;
  width: 24%;
  vertical-align: top;
  margin: 4px;
  padding: 10px;
  border-radius: 3px;
}

.ourstrength .str_bg {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  transition: all 0.5s ease-in-out;
  font-size: 25px !important;
  font-weight: 600 !important;
}

.mnhg1 {
  min-height: 238px;
}

.mnhg2 {
  min-height: 250px;
}

.mnhg3 {
  min-height: 320px;
}

.mnhg4 {
  min-height: 350px;
}

.mnhg5 {
  min-height: 400px;
}

.mnhg6 {
  min-height: 220px !important;
}

.mnhg7 {
  min-height: auto !important;
}

.mnhg8 {
  min-height: 290px !important;
}

.numcircle {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  margin: 0 auto !important;
  background-color: #ffffff;
  border: 3px solid #1930ab;
  color: #1930ab !important;
  display: grid !important;
  place-items: center !important;
}

.numcircle .MuiSvgIcon-root {
  font-size: 50px !important;
}

.ourstrength .str_bg:hover .numcircle {
  background-color: #1930ab;
  color: #ffffff !important;
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.pulse {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #fff;
  line-height: 25px;
  font-size: 18px;
  border-radius: 100%;
  top: 0px;
  right: 0px;
  color: #01b4f0;
  border: 3px solid #1930ab;
}

/* .modal-video-close-btn::before {
	content: "close" !important;
} */

.modal-video-close-btn::before,
.modal-video-close-btn::after {
  content: "";
  position: absolute !important;
}

.modal-video-close-btn::after {
  content: "" !important;
  display: none !important;
}

.modal-video-close-btn::before {
  content: "close" !important;
  transform: rotate(0deg) !important;
  background-color: transparent !important;
}

.modal-video-close-btn {
  background: #1930ab !important;
  right: 0px !important;
  position: absolute !important;
  padding: 0px 14px;
  height: 35px !important;
  border-radius: 4px;
  color: #fff !important;
  font-weight: 600;
  text-transform: uppercase;
  width: 72px !important;
  top: -38px !important;
  letter-spacing: 1px;
  line-height: 12px !important;
  cursor: pointer !important;
}

.bxicon {
  max-width: 80px;
  padding: 0;
  margin: 0;
  height: 80px !important;
}

.bxicon2 {
  max-width: 60px;
  height: auto;
  padding: 0;
  margin: 0;
}

.msgicon {
  height: 70px !important;
  width: 70px !important;
}

.msgImg {
  height: 70px !important;
  width: 70px !important;
  padding: 10px !important;
}

.videobga {
  background-color: #fff;
  width: 100%;
  border-radius: 26px;
  background-image: url(asset/images/aboutus/ourstory.svg);
  background-size: cover;
  box-shadow: rgba(255, 255, 255, aliceblue 0.15) 0px 48px 100px 0px !important;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.videobg {
  background-color: #fff;
  width: 100%;
  border-radius: 26px;
  background-image: url(asset/images/aboutus/ourstory.svg);
  background-size: cover;
  box-shadow: rgba(255, 255, 255, aliceblue 0.15) 0px 48px 100px 0px !important;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.videobg_gr {
  position: absolute !important;
  width: 90% !important;
  height: 350px !important;
  border-radius: 26px !important;
  top: -10px !important;
  background: #1830aa !important;
  background: linear-gradient(79deg, #1830aa 0%, #02b0ed 100%) !important;
  left: 32px !important;
}

.pulse-button {
  position: absolute;
  width: 80px;
  height: 80px;
  border: none;
  box-shadow: 0 0 0 0 rgba(25, 48, 171, 0.7);
  border-radius: 50%;
  background-color: #1930ab;
  background-image: url(asset/images/aboutus/videoic.webp);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.pulse-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(25, 48, 171, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(25, 48, 171, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(25, 48, 171, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(25, 48, 171, 0);
  }
}

@-webkit-keyframes smallPulse {
  to {
    box-shadow: 0 0 0 15px rgba(25, 48, 171, 0);
  }
}

@-moz-keyframes smallPulse {
  to {
    box-shadow: 0 0 0 15px rgba(25, 48, 171, 0);
  }
}

@-ms-keyframes smallPulse {
  to {
    box-shadow: 0 0 0 15px rgba(25, 48, 171, 0);
  }
}

@keyframes smallPulse {
  to {
    box-shadow: 0 0 0 15px rgba(25, 48, 171, 0);
  }
}

.allic {
  width: 70px !important;
  margin: 0 auto;
  margin-bottom: 14px;
}

#bridge-lending-solutions .allic {
  width: 70px !important;
  margin: 0 auto;
  margin-bottom: 0px;
}

.boxbg {
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.min_312 {
  min-height: 312px !important;
}

.ds_bg_1 {
  background: #1930ab !important;
  height: 300px !important;
  background-position: center center;
  position: relative;
  background-repeat: no-repeat;
}

.ds_bg_2 {
  background: #01b4f0 !important;
  height: 300px !important;
  background-position: center center;
  position: relative;
  background-repeat: no-repeat;
}

.dpabtn_1 {
  background-color: #1930ab !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 8px 28px !important;
  letter-spacing: 2px !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}

.dpabtn_2 {
  background-color: #01b4f0 !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 8px 28px !important;
  letter-spacing: 2px !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}

.dpabtn_gr {
  background-color: #d5dbe5 !important;
  color: #4d4f5d !important;
  border-radius: 50px !important;
  padding: 8px 20px !important;
  letter-spacing: 1px !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  font-size: 10px !important;
  font-weight: 700 !important;
}

.whitebx {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 20px;
}

.sutrabox {
  background-color: transparent;
  border-radius: 6px;
  padding: 20px;
  height: 150px !important;
  display: grid !important;
  place-items: center !important;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
}

.sutrabox:hover {
  background-color: #fff !important;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
  transition: 1.2s;
}

.sutrabox .MuiSvgIcon-root {
  font-size: 10px;
  text-align: center;
  color: #f00 !important;
}

.sutratxt {
  color: #d8d8d8 !important;
}

.sutrabox:hover .sutratxt {
  color: #1930ab !important;
  transition: 1.4s;
}

.min_ht2 {
  min-height: 240px !important;
}

.heism {
  min-height: 75px !important;
}

.pdf {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #f00;
  display: flex;
  align-items: center;
  justify-content: center;
}

.insightmidbg {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.icbg {
  background-color: #dde3ed !important;
  margin: 5px 5px !important;
}

.icbg2 {
  background-color: #dde3ed !important;
  margin: 5px 5px !important;
}

.twitter {
  color: #1da1f2 !important;
}

.fb {
  color: #4267b2 !important;
}

.linkedin {
  color: #0a66c2 !important;
}

.insta {
  color: #fb3958 !important;
}

.icbl {
  color: #1930ab !important;
}

/* .reportinfo {
  margin-top: 6px;
} */

.reportinfo .reportm_bg {
  background-color: #fff !important;
  padding: 15px !important;
  border-radius: 6px !important;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px !important;
}

.reportinfo .reportimg_bg {
  background-color: #4d4f5d;
  height: 175px;
  background-size: cover;
  background-position: center center !important;
}

/* newsletter2025 css start*/

#newsl2025.reportm_bg {
  width: 100% !important;
  height: 250px !important;
  /* overflow: auto !important; */
  padding: 0px !important;
  scrollbar-width: none !important;
  /* position: relative;
  display: flex;
  flex-direction: column; */
}

#newsletter #newsl2025 .reportimg_bg {
  width: 100% !important;
  height: auto !important;
  background-position: center center !important;
  line-height: 0px !important;
}

#newsl2025 .newsletter_2025 {
  width: 100% !important;
  height: auto !important;

  transform: translateY(0) !important;
  transition: transform 10s ease-in-out !important;
}

#newsl2025:hover .newsletter_2025 {
  transform: translateY(calc(250px - 100%)) !important;
}

/* newsletter2025 css end*/

.reportinfo .reporttitle {
  font-size: 18px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize !important;
}

.line {
  width: 50px;
  height: 2px;
  border-radius: 20px;
  background-color: #01b4f0;
  transition: width 2s;
}

.reportinfo .reportm_bg:hover .line {
  width: 150px;
}

.one {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 53px !important;
}

.thr {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 78px !important;
}

.footer {
  background-color: #d8d8d8 !important;
  margin-top: 0px !important;
}

.foottop {
  padding: 20px 0px;
}

.foottop2 {
  padding: 40px 0px;
}

.stay {
  font-size: 30px !important;
  color: #838282 !important;
  line-height: 43px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}

.stay1 {
  font-size: 20px !important;
  color: #ffffff !important;
  line-height: 30px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
}

.stay2 {
  font-size: 20px !important;
  color: #1930ab !important;
  line-height: 30px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
}

ul#foot_newsletter {
  min-width: 100%;
  list-style: outside none none;
  /* text-align: right !important; */
  margin: 0px;
  padding: 0px;
  text-align: center !important;
}

ul#foot_newsletter li {
  display: inline-block;
  /* width: auto; */
  vertical-align: top;
  margin: 0px 3px;
  width: 100%;
}

ul#foot_newsletter li:nth-child(1) {
  min-width: 300px !important;
  width: 98% !important;
  margin-bottom: 10px;
}

.footerlink {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footerlink .menutt {
  align-items: center !important;
  display: flex !important;
  margin: 0px 0px !important;
  font-size: 14px !important;
  color: #4d4f5d !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  background-color: transparent !important;
  text-transform: capitalize !important;
  padding: 0px !important;
  text-align: left !important;
}

.footerlink .menutt:hover {
  color: #1930ab !important;
}

.footerlink .menutt .MuiButton-label {
  justify-content: left !important;
}

.footerlink .ftwitter {
  width: 15px !important;
  height: 15px !important;
}

.footerlink li a {
  color: #4d4f5d !important;
  font-size: 14px !important;
  font-weight: 500;
}

.footerlink li a:hover {
  color: #1930ab !important;
}

.footerlink li {
  padding-bottom: 8px !important;
}

.makeStyles-toTop-1 {
  right: 1%;
  background-color: #1930ab !important;
}

.makeStyles-toTop-1:hover {
  right: 1%;
  background-color: #01b4f0 !important;
}

.eAyARa {
  background-color: #fff !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  line-height: 40px !important;
  border: none !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}

.eWjCzc {
  background-color: #1930ab !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  line-height: 40px !important;
  border: none !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
  color: #fff !important;
}

.boxcontent {
  background-color: #fff;
  border-radius: 6px;
  padding: 30px;
  text-align: center;
  width: 100%;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
  position: relative;
}

.test_text {
  width: 82%;
  margin: 0 auto;
}

.sidebgcsr {
  background-color: #1930ab;
  height: 700px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.csrinfo {
  padding: 6% 0% !important;
}

.eventbg {
  background-color: #fff;
  height: 60vh !important;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
}

.blink_text {
  animation-name: blinker;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  color: #1930ab;
  font-size: 13px !important;
  letter-spacing: 1px !important;
}

@keyframes blinker {
  0% {
    opacity: 2;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 2;
  }
}

.listdetails ul {
  padding-left: 20px;
}

.listdetails ul li {
  margin-bottom: 10px;
  margin-left: 0px;
}

.videback {
  width: 100%;
  height: 400px;
  background-color: #fff;
  z-index: -1;
}

.videoback .player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.reason .MuiSvgIcon-root {
  width: 18px;
  color: #fff;
}

.reason .MuiFormControlLabel-root .MuiTypography-body1 {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.bluebg {
  background-color: #1930ab;
  height: 100%;
  color: #fff !important;
  border-radius: 8px;
  padding: 16px 20px;
}

.casestudy_bg {
  height: 300px;
  background-size: cover;
  border-radius: 10px;
  background-position: left top;
}

.graybbx {
  background-color: #eff2ff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 20px;
}

.addinfo .MuiListItem-gutters {
  padding-left: 0px !important;
}

.addinfo .MuiListItem-root {
  padding: 0px !important;
}

.locicon {
  max-width: 269px;
  height: auto;
  padding: 0;
  margin: 0;
  width: 100%;
}

.awardsindex {
  width: 120px;
  height: 120px;
  background-color: #85898d;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 10px;
  margin: 0 auto;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.awardsindexcircle {
  width: 150px;
  height: 150px;
  background-color: #85898d;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 75px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.move {
  margin-bottom: 20px;
}

.move .movetext {
  font-size: 15px;
  font-weight: 600;
}

.move .movetext span {
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
}

.cl1 {
  color: #1930ab;
}

.cl2 {
  color: #4d4f5d;
}

.team {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin: 0 auto;
  background-size: cover;
  background-position: center top;
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out;
}

.team_testi {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin: 0 auto;
  background-size: cover;
  background-position: center top;
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out;
}

.teama {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin: 0 auto;
  background-size: cover;
  background-position: center top;
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out;
}

.teaminfo {
  font-size: 22px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
  margin-bottom: 14px !important;
}

.dpateam:hover .team {
  filter: grayscale(0%);
}

.tmlink {
  cursor: pointer;
}

.b_modal {
  z-index: +9999999999999 !important;
}

.b_modal .MuiDialog-paperWidthSm {
  min-width: 40% !important;
}

.d_modal .MuiDialog-paperWidthSm {
  min-width: 60% !important;
}

.teammodal .MuiDialogContent-dividers {
  border-top: none !important;
}

.graybbx h6 {
  font-size: 14px !important;
  line-height: 20px !important;
  margin-bottom: 0px !important;
}

.chkbx .MuiTypography-body1 {
  font-size: 13px;
  font-weight: 500;
  color: #000000;
}

.chkbx {
  display: flex !important;
}

/* .chkbx .label {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  position: relative;
  top: 10px !important;
  left: -16px;
} */

.chkbx .label {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  position: relative;
  top: 10px !important;
  left: -16px;
  user-select: none !important;
}

.chkbx .label2 {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  user-select: none !important;
}

.checkbx-error .MuiAlert-message {
  padding: 2px 0px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: red !important;
  line-height: normal !important;
}

.checkbx-error .MuiAlert-standardError .MuiAlert-icon {
  font-size: 20px !important;
  padding: 0px !important;
}

.checkbx-error .MuiAlert-root {
  padding: 6px 10px !important;
}

.mobilemenuview {
  background-color: #fff;
}

.mobilemenuview .MuiListItem-gutters {
  padding: 0px !important;
}

.desktopviewmenu {
  display: block;
}

.mobileviewmenu {
  display: none;
}

.owldot .owl-nav {
  position: relative;
  top: 12px;
}

.owldot .owl-nav button {
  background-color: #ebf2fc !important;
  width: 40px;
  height: 40px;
  margin: 0px 5px;
  border-radius: 100%;
  font-size: 30px !important;
  line-height: 0px !important;
  color: #1930ab !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 700px) {
  .rec-arrow {
    display: none;
  }

  .eWjCzc {
    display: none;
  }

  .test_text {
    width: 100%;
  }

  .boxcontent {
    padding: 2px !important;
  }

  .boxcontent h6 {
    text-align: justify;
  }

  .b_modal .MuiDialog-paperWidthSm {
    min-width: 92% !important;
  }
}

@media (max-width: 600px) {
  .mainsection {
    width: 100%;
    height: auto;
    display: block;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 100px;
  }

  h1 {
    font-size: 32px !important;
    line-height: 38px !important;
  }

  /* h2 {
		font-size: 32px !important;
		line-height: 37px !important;
	} */

  h6 {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  /* 
  .desktopviewmenu {
    display: none !important;
  }

  .mobileviewmenu {
    display: block;
  } */

  .whydecimal {
    height: auto !important;
    margin-bottom: 0px !important;
  }

  .whydecimal .owl-carousel {
    margin: 0px !important;
  }

  .whydecimal .owl-item.center>div {
    margin-top: 20px !important;
    padding: 20px 40px;
  }

  .whydecimal .owl-item>div {
    margin-top: 20px !important;
    padding: 20px 40px;
  }

  #corevalue {
    margin-top: 0px !important;
  }

  .fullcontainer {
    height: auto !important;
  }

  .rowfullheight {
    height: auto !important;
  }

  .left {
    height: auto !important;
  }

  .red {
    height: auto !important;
  }

  .corvaluebx {
    padding: 4% 2px;
  }

  .footer .stay {
    font-size: 20px !important;
    text-align: center;
  }

  .section,
  .section2 {
    padding-top: 25px !important;
  }

  .eventbg {
    height: 35vh !important;
  }

  #newsl2025:hover .newsletter_2025 {
    transform: none !important;
  }
}

.addinfo .MuiListItemText-root .MuiTypography-body1 {
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  color: #1930ab !important;
}

.pages {
  top: 60px !important;
  position: sticky !important;
}

.pages .stiky2 {
  border-radius: 6px !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

#sitemap .sitemaphdd {
  font-size: 15px !important;
  color: #000 !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  background-color: transparent !important;
  text-transform: capitalize !important;
  margin-bottom: 5px !important;
}

#sitemap .brdbx {
  border: 1px solid #d8d8d8 !important;
  border-radius: 6px !important;
  padding: 5px 12px !important;
}

#sitemap .menuheading {
  margin-bottom: 4px !important;
}

#sitemap .smitem {
  font-size: 13px !important;
  text-decoration: none !important;
  color: #1930ab;
  position: relative;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 16px !important;
}

#sitemap .itemdiv {
  margin: 4px 0px !important;
}

#sitemap .lgscroll {
  height: 66vh !important;
  overflow: auto !important;
}

#sitemap .smscroll {
  height: 28vh !important;
  overflow: auto !important;
}

#sitemap #solution .smscroll {
  height: 15vh !important;
  overflow: auto !important;
}

#sitemap .whitebx {
  padding: 14px !important;
}

#sitemap.section {
  padding-top: 70px !important;
}

ol {
  padding-left: 32px !important;
}

#discover_hidden_intelli .mnhg1 {
  min-height: 372px !important;
}

/* New Custom 4 Bottom Bar */

.owldot2 .owl-stage-outer {
  padding-bottom: 25px !important;
}

.owldot2 .owl-nav {
  position: relative;
  top: 10px;
  text-align: center !important;
}

.owldot2 .owl-nav button {
  background-color: #ebf2fc !important;
  width: 40px;
  height: 40px;
  margin: 0px 15px;
  border-radius: 100% !important;
  font-size: 30px !important;
  line-height: 0px !important;
  color: #1930ab !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* New Media Query Added for all and Responsive */

@media (max-width: 600px) {
  #sitemap {
    padding-top: 45px !important;
  }

  #sitemap .lgscroll {
    height: auto !important;
  }

  #sitemap .smscroll {
    height: auto !important;
  }

  .mobcenter {
    text-align: center !important;
  }

  /* .mainhead {
		font-size: 36px !important;
		line-height: 45px !important;
	}

	.subhead {
		font-size: 20px !important;
		line-height: 22px !important;
	} */

  .section_mob {
    padding-top: 30px !important;
    padding-bottom: 25px !important;
  }
}

.subpagebanner {
  max-width: 50%;
  height: auto;
  padding: 0;
  margin: 0;
}

.sechead {
  font-size: 32px !important;
  line-height: 45px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.testimonial {
  border: 0px solid #fff;
  text-align: justify;
  padding: 10px;
  border-radius: 45px;
  position: relative;
  z-index: 2;
}

.testimonial::before {
  content: "";
  position: absolute;
  left: 0px;
  top: -35px;
  background: url(./asset/images/testimonials/open_testimonial.webp) no-repeat transparent !important;
  background-position: center center !important;
  background-size: cover !important;
  background-size: 60%;
  width: 50px;
  height: 50px;
  transform: rotate(180deg);
  /* background-position: 34px 15px; */
}

.testimonial::after {
  content: "";
  position: absolute;
  right: 0px;
  bottom: -34px;
  background: url(./asset/images/testimonials/open_testimonial.webp) no-repeat transparent !important;
  background-position: center center !important;
  background-size: cover !important;
  background-size: 60%;
  width: 50px;
  height: 50px;
  /* background-position: 34px 19px; */
}

.testimonial_seperator {
  margin-left: 10px !important;
  height: 2px !important;
  background-color: #4d4f5d !important;
  width: 80px !important;
  border-radius: 6px !important;
}

.testimonial_org_name {
  border-top-width: 200px !important;
  padding: 10px !important;
}

.esg_solutions {
  background: #ebf2fc;
  text-align: center;
  height: 420px !important;
  margin-bottom: 16%;
}

.sub_section {
  padding: 4% !important;
  padding-top: 5% !important;
}

.sample_dash_img {
  /* background-color: #d7d7d7; */
  height: auto !important;
  /* background-size: cover;
  background-position: center center; */
  width: 100% !important;
  /* min-height: 380px; */
}

#sba_solutions .boxbg {
  padding-top: 26px !important;
}

#sba_solutions .MuiPaper-root {
  background-color: transparent !important;
}

#sba_solutions .MuiStepLabel-label .MuiTypography-body1,
#sba_solutions .MuiStepContent-root .MuiTypography-body1 {
  font-size: 16px !important;
}

#sba_solutions .MuiSvgIcon-root {
  /* fill: #1930ab !important; */
  font-size: 1.5rem;
}

#sba_solutions .MuiStepConnector-line {
  display: none !important;
}

#sba_solutions .MuiStepper-root {
  padding: 0px !important;
}

#esg_news .boxbg {
  padding-top: 26px !important;
}

#esg_news.insight_edge .boxbg {
  padding-top: 15px !important;
  /* justify-content: center !important; */
  border-left: 5px solid #1930ab !important;
}

.sb_bg1 {
  background-color: #00b0f0;
}

.sb_bg2 {
  background-color: #8e9eee;
}

.sb_bg3 {
  background-color: #69d8ff;
}

.sbaprocess_boxbg {
  padding: 40px 60px;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.sbaprocess_boxbg .process_txt {
  font-weight: 500 !important;
}

.sbaprocess_boxbg_lg {
  height: 380px !important;
}

.sbaprocess_boxbg_md {
  height: 220px !important;
}

.sbaprocess_boxbg_sm {
  height: 182px !important;
}

.process_number {
  font-size: 45px !important;
  font-weight: 600 !important;
  margin-bottom: 18px !important;
  color: #fff !important;
}

.new_pulse {
  border-radius: 50%;
  color: #01b4f0;
  padding-left: 2px !important;
}

.ourstrength .str_bg:hover .new_pulse {
  color: #ffffff !important;
}

.whydecimal .owl-item:hover .MuiBox-root {
  background: #01b4f0;
}

.whydecimal .owl-item:hover .hoverwh {
  color: #fff !important;
}

/* Video on landing page banner css started robopulse*/

.landing_side_vdo {
  background-color: #fff;
  width: 100%;
  border-radius: 26px;
  box-shadow: rgba(255, 255, 255, aliceblue 0.15) 0px 48px 100px 0px !important;
  height: auto !important;
  min-height: 360px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  margin: auto !important;
  background-size: cover !important;
}

@media (max-width: 767px) {

  #sba_solutions .MuiStepLabel-label .MuiTypography-body1,
  #sba_solutions .MuiStepContent-root .MuiTypography-body1 {
    font-size: 13px !important;
  }
}

@media (max-width: 600px) {
  .landing_side_vdo {
    background-size: contain !important;
    min-height: 250px !important;
  }
}

/* ecopulse and creditpulse video on landing page banner css started */
.creditvdo {
  background-color: #fff;
  width: 100%;
  border-radius: 26px;
  box-shadow: rgba(255, 255, 255, aliceblue 0.15) 0px 48px 100px 0px !important;
  height: auto !important;
  min-height: 360px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  margin: auto !important;
  background-size: contain !important;
}

.pulse_button_2 {
  box-shadow: 0 0 0 0 rgba(25, 48, 171, 0.7);
  background-color: #01b4f0 !important;
  /* background-image: url(asset/images/dmaas_home/videoic.webp); */
}

video::-moz-presentation-control {
  display: none !important;
}

video {
  width: 100% !important;
}

@media (max-width: 876px) {
  .desktopviewmenu {
    display: none !important;
  }

  .mobileviewmenu {
    display: block;
  }
}

.lside {
  border-right: 2px solid #1930ab;
}

@media (max-width: 960px) {
  .lside {
    border-right: none !important;
  }
}

/* New Css Added for Producta Pages :: Eco and Credit Pulse */

.dpabtn_sm1 {
  background-color: #1930ab !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 8px 20px !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}

.dpabtn_sm2 {
  background-color: #00b0f0 !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 8px 20px !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}

.cardbx {
  background-color: #fff;
  padding: 40px !important;
  padding-bottom: 15px !important;
  text-align: center;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  transition: all 0.5s ease-in-out;
}

.cardbx h6 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 100px !important;
}

.centered_data {
  text-align: center !important;
  width: 120px !important;
}

#mui-table .MuiTableCell-head {
  color: #fff !important;
  padding: 4px 12px !important;
  line-height: 18px !important;
  font-size: 14px !important;
}

#mui-table .MuiTableCell-body {
  padding: 8px 12px !important;
  line-height: 18px !important;
  font-size: 14px !important;
  color: #4d4f5d !important;
}

#mui-table .lstrow {
  background-color: #dedede !important;
  margin-bottom: 4px !important;
  border-bottom: 2px solid #1930ab !important;
}

#mui-table .MuiTableHead-root {
  background-color: #1930ab !important;
}

/* FAQ Section Started */

#faq-accordian h4 {
  font-family: "Roboto", sans-serif !important;
}

#faq-accordian h6 {
  font-family: "Roboto", sans-serif !important;
}

#faq-accordian .MuiAccordionSummary-root {
  background-color: #fff !important;
  color: #4d4f5d !important;
  border-radius: 6px !important;
  margin-bottom: 10px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#faq-accordian .Mui-expanded .MuiAccordionSummary-root {
  background-color: #1930ab !important;
  color: #fff !important;
}

#faq-accordian .MuiAccordion-root {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

#faq-accordian .MuiAccordionDetails-root {
  background-color: #fff !important;
  border-radius: 6px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 8px 16px !important;
}

#faq-accordian .MuiAccordionSummary-expandIcon.Mui-expanded {
  color: #fff !important;
}

#faq-accordian .MuiAccordionSummary-expandIcon {
  color: #4d4f5d !important;
}

#faq-accordian .MuiAccordion-root::before {
  display: none !important;
}

#faq-accordian .accordian_head {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  text-align: left !important;
}

#faq-accordian .accordian_content {
  font-size: 14px !important;
  text-align: justify !important;
}

#faq-accordian .MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px !important;
}

#faq-accordian .MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0 !important;
}

@media (max-width: 767px) {
  #product_cta.flx_fs {
    display: block !important;
    justify-content: left !important;
    text-align: left !important;
  }

  #product_cta.flx_sb,
  #product_cta.flx_center {
    display: block !important;
    justify-content: left !important;
    text-align: left !important;
  }

  .mnhg8 {
    min-height: 272px !important;
  }
}

@media (max-width: 600px) {
  .cardbx h6 {
    min-height: auto !important;
    height: auto !important;
  }

  .mnhg8 {
    min-height: auto !important;
  }
}

.banner-head {
  background-color: #4d4f5d;
  height: 175px;
  background-size: cover;
  background-position: center center;
}

.inside_images {
  max-width: 70%;
  height: auto;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .inside_images {
    min-width: 100% !important;
  }

  .creditvdo {
    min-height: 200px !important;
  }

  .pulse-button {
    height: 50px !important;
    width: 50px !important;
  }
}

.floating-btn {
  height: auto;
  position: fixed;
  top: calc(50% - 20px) !important;
  right: -62px;
  z-index: 9999999;
  transform: rotate(90deg) !important;
}

.float_btn {
  background-color: #1930ab !important;
  color: #fff !important;
  padding: 8px 28px !important;
  padding-bottom: 10px !important;
  letter-spacing: 1px !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  border-bottom-right-radius: 26px !important;
  border-bottom-left-radius: 26px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.contact_sales_btn {
  background-color: #00b0f0 !important;
  border-radius: 50px !important;
  padding: 0px 15px !important;
  /* box-shadow: none !important; */
  font-size: 15px !important;
  color: #fff !important;
  text-decoration: none !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  height: 28px !important;
  margin-top: 5px !important;
  margin-left: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.contact_sales_btn:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}

@media (max-width: 767px) {
  .floating-btn {
    top: calc(50% - 20px) !important;
    right: -40px;
  }

  .clico {
    display: none !important;
  }

  .float_btn {
    padding: 6px 16px !important;
    padding-bottom: 6px !important;
    border-bottom-right-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
  }
}

#submit-message h4 {
  text-transform: inherit !important;
}

.foottop2 .MuiButton-startIcon {
  color: #4d4f5d !important;
  height: auto !important;
  width: 20px !important;
}

.whitebx a {
  text-decoration: underline !important;
  font-weight: 600 !important;
}

.MuiDialogContent-root a {
  text-decoration: underline !important;
  font-weight: 600 !important;
  word-break: break-word !important;
}

.link {
  font-weight: 600 !important;
  text-decoration: underline !important;
  word-break: break-word !important;
}

/* awards top list items css staterd */
ul#award_items {
  width: 100%;
  list-style: outside none none;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex !important;
  overflow: auto !important;
}

ul#award_items li {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 3px;
  width: 16%;
  padding: 10px 0px !important;
}

/* new css wrt articles */
#article-page .whitebx {
  margin-top: 25px !important;
}

#article-page h1 {
  margin-bottom: 0px !important;
}

#article-page .whitebx.mt1 {
  margin-top: 8px !important;
}

#article-page .whitebx .fallacies li {
  margin-bottom: 14px !important;
}

#article-page .requiem_img {
  /* border: 1px solid #4d4f5da3 !important; */
  border-radius: 6px !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
}

.reporttitle_box {
  margin: 16px 0px !important;
}

#case-studies .whitebx {
  margin-top: 12px !important;
}

#newsletter .reportimg_bg {
  background-position: left !important;
}

.maintabbedsection.linkedtabs .MuiTab-root {
  opacity: 1 !important;
}

.maintabbedsection.linkedtabs .MuiTab-root.Mui-selected {
  background-color: #00b0f0 !important;
  border-bottom: none !important;
  color: #fff !important;
}

.maintabbedsection.linkedtabs .MuiTabs-indicator {
  display: none !important;
}

.preview-newsletter {
  width: 100% !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.m0 {
  margin: 0px !important;
}

.fullimg {
  width: 100% !important;
  margin: auto !important;
  height: auto !important;
  border-radius: 6px !important;
}

.txtico {
  color: #4d4f5d !important;
  text-transform: none !important;
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 400 !important;
  margin-bottom: 0px !important;
  text-align: start !important;
  padding: 4px 0px !important;
}

.txtico:hover {
  background-color: transparent !important;
  color: #1930ab !important;
}

.imgsource-txt {
  font-size: 14px !important;
  line-height: 22px !important;
}

/* css added 23-10-2023 */

/* Video on AI page banner css started */

.ai_vdo {
  background-color: #fff;
  width: 350px;
  border-radius: 26px;
  box-shadow: rgba(255, 255, 255, aliceblue 0.15) 0px 48px 100px 0px !important;
  height: auto !important;
  min-height: 360px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  margin: auto !important;
  background-size: cover !important;
}

@media (max-width: 700px) {
  .ai_vdo {
    background-size: contain !important;
    min-height: 250px !important;
  }
}

.gen_ai {
  width: 450px !important;
}

#artificial-intelligence .allic {
  width: 60px !important;
  margin-bottom: 12px !important;
}

#artificial-intelligence .cardbx h6 {
  height: auto !important;
  overflow: visible !important;
  text-overflow: initial !important;
  display: inherit !important;
  margin-bottom: 0px !important;
}

#artificial-intelligence .cardbx {
  padding: 20px 40px !important;
  padding-bottom: 10px !important;
  display: block !important;
}

#artificial-intelligence .solution_fx_bx {
  min-height: 372px !important;
}

.addinfo .MuiListItemText-secondary {
  word-wrap: break-word !important;
}

/* css added 3-11-23 */
.service_img {
  width: 150px !important;
  height: 150px !important;
  overflow: hidden !important;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.img_box {
  width: 150px !important;
  height: 150px !important;
  text-align: center !important;
  border-radius: 50% !important;
  overflow: hidden;
  -webkit-transform: scale(1.06) !important;
  transform: scale(1.06) !important;
  align-items: center !important;
  justify-content: center !important;
  display: inline-grid !important;
}

.services-card {
  margin-top: 16px !important;
}

.services-card:hover {
  cursor: pointer !important;
}

.services-card:hover .service_img {
  -webkit-transform: scale(1.08) !important;
  transform: scale(1.08) !important;
}

.product_bx .prod_img {
  width: 100% !important;
  /* height: 55vh !important; */
  height: 380px !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
}

.product_bx .pulse-button {
  height: 50px !important;
  width: 50px !important;
}

.prod_img .top {
  height: calc(100% - 145px) !important;
  display: grid !important;
  place-items: center !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.prod_img .bottom {
  height: 145px !important;
  padding: 12px 12px !important;
}

.subtxt {
  font-size: 13px !important;
  line-height: 20px !important;
  margin: 0px !important;
  padding: 0px !important;
  height: 80px !important;
  margin-top: 10px !important;
}

.product_bx .bl_overlay {
  background-color: rgba(25, 48, 171, 0.8) !important;
}

.product_bx .skbl_overlay {
  background-color: rgba(0, 176, 240, 0.8) !important;
}

.reso_bx .bl_overlay {
  background-color: rgba(25, 48, 171, 0.8) !important;
}

.reso_bx .skbl_overlay {
  background-color: rgba(0, 176, 240, 0.8) !important;
}

/* #products h6 {
	font-size: 13px !important;
	line-height: 1.2 !important;
	margin-top: 8px !important;
	margin-bottom: 0px !important;
} */

.read_more {
  text-transform: none !important;
  font-size: 13px !important;
}

/* 
#emp .emp1_img {
	width: 100% !important;
	height: 80vh !important;
	background-size: contain !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
}

#emp .emp2_img {
	display: grid !important;
	place-items: center !important;
	width: 100% !important;
	height: 50vh !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
}  */

/* #emp .pulse-button {
	position: relative !important;
} */
/* 
.mainimg {
	position: relative !important;
}

.emp1_img {
	position: absolute !important;
	width: 80% !important;
}

.img2 {
	position: relative !important;
	width: 45% !important;
	height: auto !important;
	top: 27% !important;
	left: 41% !important;
}

.emp2_img {
	width: 100% !important;
} */

.mainbox {
  position: relative;
  text-align: left !important;
}

.image-container {
  position: absolute !important;
  display: grid !important;
  place-items: center !important;
  place-content: center !important;
}

.fold21img {
  width: 78% !important;
}

.childimg {
  position: relative;
  /* top: -300px !important;
	left: 0;
	width: 100%;
	height: 100%; */

  /* top: -135% !important;
	left: 20px; */
  top: -82% !important;
  left: 82%;
  width: 85% !important;
}

.playbtn {
  /* position: relative !important;
	 top: -420px; */

  /* top: -100% !important;
	position: absolute !important;
	right: 16% !important; */
  top: -45% !important;
  position: absolute !important;
  left: 120% !important;

  /* left: 50%;
	transform: translate(-50%, -50%); */
}

@media (max-width: 1200px) {
  .childimg {
    bottom: -18% !important;
    left: 50% !important;
    width: 80% !important;
  }

  .playbtn {
    height: 40px !important;
    width: 40px !important;
    top: -40% !important;
    position: absolute !important;
    left: 95% !important;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .section {
    padding-top: 8% !important;
  }
}

@media (max-width: 960px) {
  .fold21img {
    width: 75% !important;
    max-width: 320px !important;
    text-align: center !important;
  }

  .childimg {
    bottom: -18% !important;
    left: 12% !important;
    width: 50% !important;
  }

  .playbtn {
    height: 40px !important;
    width: 40px !important;
    top: -45% !important;
    position: absolute !important;
    left: 55% !important;
  }
}

@media (max-width: 600px) {
  .fold21img {
    width: 75% !important;
    max-width: 320px !important;
    text-align: center !important;
    margin-bottom: 100px !important;
  }

  .childimg {
    bottom: 0 !important;
    left: 42% !important;
    width: 65% !important;
    top: -120% !important;
  }

  .playbtn {
    height: 40px !important;
    width: 40px !important;
    top: -78% !important;
    position: absolute !important;
    left: 85% !important;
  }
}

@media (max-width: 440px) {
  .childimg {
    bottom: 0 !important;
    left: 25% !important;
    width: 65% !important;
    top: -120% !important;
  }

  .playbtn {
    top: -78% !important;
    left: 70% !important;
  }
}

@media (max-width: 380px) {
  .childimg {
    bottom: 0 !important;
    left: 20% !important;
    width: 65% !important;
    top: -120% !important;
  }

  .playbtn {
    top: -78% !important;
    left: 65% !important;
  }
}

/* @media (max-width: 500px) {
	.childimg {
		top: -125% !important;
		left: 10px;
		height: 150px;
	}


	.playbtn {
		height: 40px !important;
		width: 40px !important;
		top: 50% !important;
		position: absolute !important;
		right: 28% !important;
	}
} */

/* resources */

.reso_bx .reso_img {
  height: 300px !important;
  width: 100% !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.reso_bx .img_over {
  width: 100% !important;
  height: 100% !important;
  display: grid !important;
  place-items: center !important;
}

.awards {
  background-color: #d8d8d8 !important;
  border-radius: 8px !important;
  padding: 18px !important;
}

.awards_img {
  width: 70% !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  border-radius: 7px !important;
  max-width: 150px !important;
}

.fdpalogo {
  width: 300px !important;
  padding: 70px 0px 30px 0px !important;
  display: flex !important;
  margin: auto !important;
}

.f_li2 {
  min-width: 20px !important;
}

.social-icons {
  text-align: center !important;
  transform: translateY(0) !important;
}

.social-icons-button:hover {
  /* background-color: #1930ab !important;
	color: #fff !important; */
  transform: translateY(-8px) !important;
}

.social-icons-button {
  background-color: #fff !important;
  margin: 0px 6px !important;
  color: #1930ab !important;
  padding: 6px !important;
  border-radius: 50% !important;
}

.b_modal .rec-dot_active {
  background-color: rgba(25, 48, 171, 0.5) !important;
  box-shadow: 0 0 1px 3px rgba(25, 48, 171, 1) !important;
}

.b_modal .rec-dot:hover,
.b_modal .rec-dot:focus {
  box-shadow: 0 0 1px 3px rgba(25, 48, 171, 1) !important;
}

/* Media QUeries Started */

@media (max-width: 767px) {
  #awards-owl {
    display: none !important;
  }

  .awardsindexcircle {
    height: 130px !important;
    width: 130px !important;
  }
}

@media (max-width: 960px) {
  .rowfullheight {
    height: auto !important;
  }

  .fullcontainer {
    height: auto !important;
  }

  .corvaluebx {
    padding: 0px !important;
  }

  ul#award_items li {
    width: 15%;
  }

  .awardsindex {
    width: 100px;
    height: 90px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 36px !important;
    line-height: 42px !important;
  }

  .newh1 {
    font-size: 26px !important;
    line-height: 32px !important;
  }

  h2 {
    font-size: 26px !important;
    line-height: 32px !important;
  }

  #mainpg h2 {
    font-size: 18px !important;
    line-height: 30px !important;
    margin-bottom: 8px !important;
    font-weight: 500 !important;
  }

  h4 {
    font-size: 13px !important;
    line-height: 20px !important;
  }

  h6 {
    font-size: 13px !important;
    line-height: 22px !important;
  }

  .h2v2 {
    font-size: 20px !important;
    line-height: 26px !important;
  }

  .reportinfo .reportm_bg .thr {
    min-height: 70px !important;
  }

  #sba_solutions #product_cta h6 {
    margin-bottom: 0px !important;
  }

  #esg_news #product_cta h6 {
    margin-bottom: 0px !important;
  }

  #esg_news #product_cta.flx_fs {
    display: block !important;
    justify-content: center !important;
    text-align: center !important;
  }

  #sba_solutions #product_cta.flx_fs {
    display: block !important;
    justify-content: center !important;
    text-align: center !important;
  }

  #esg_news #product_cta.flx_sb,
  #product_cta.flx_center {
    display: block !important;
    justify-content: center !important;
    text-align: center !important;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .newh1 {
    font-size: 18px !important;
    line-height: 28px !important;
    margin-bottom: 0px !important;
    font-weight: 400 !important;
  }

  h2 {
    font-size: 18px !important;
    line-height: 28px !important;
    margin-bottom: 0px !important;
    font-weight: 400 !important;
  }

  #mainpg h2 {
    font-size: 18px !important;
    line-height: 30px !important;
    margin-bottom: 8px !important;
    font-weight: 500 !important;
  }

  h4 {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  h5 {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  h6 {
    font-size: 13px !important;
    line-height: 22px !important;
  }

  .imgsource-txt {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  .graybbx h6 {
    font-size: 11px !important;
    line-height: 18px !important;
  }

  .alltxfieldlg .MuiTypography-body1 {
    font-size: 11px;
    text-align: left !important;
  }

  .alltxfieldlg .MuiSvgIcon-fontSizeSmall {
    font-size: 15px !important;
  }

  .alltxfieldlg .label {
    font-size: 11px !important;
  }

  .h2v2 {
    font-size: 17px !important;
    line-height: 22px !important;
  }

  .stay1 {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .mobbtn {
    padding: 4px 12px !important;
    font-size: 11px !important;
    letter-spacing: 1px !important;
  }

  .mnhg5 {
    height: auto !important;
    min-height: auto !important;
  }

  .mnhg4 {
    height: auto !important;
    min-height: auto !important;
  }

  .mnhg6 {
    height: auto !important;
    min-height: auto !important;
  }

  .mnhg3 {
    height: auto !important;
    min-height: auto !important;
  }

  .mnhg2 {
    height: auto !important;
    min-height: auto !important;
  }

  .ourstrength .str_bg {
    padding: 10px 10px !important;
    min-height: auto !important;
    text-align: left !important;
  }

  .ourstrength .mob_card .str_bg {
    padding: 10px 10px !important;
    min-height: auto !important;
    text-align: left !important;
    display: flex !important;
    align-items: center !important;
  }

  .str_bg .bxicon {
    max-width: 50px;
    height: 50px !important;
    margin-right: 10px !important;
    margin-top: 12px;
  }

  .mainhead {
    font-size: 18px !important;
    line-height: 24px !important;
    text-align: left !important;
  }

  .subhead {
    margin-top: 50px !important;
    text-align: left !important;
    font-size: 12px !important;
    margin-bottom: 4px !important;
  }

  .edition {
    text-align: left !important;
  }

  .mainsmhead {
    font-size: 13px !important;
    line-height: 20px !important;
    text-align: left !important;
  }

  .teaminfo {
    font-size: 16px !important;
    margin-bottom: 2px !important;
  }

  .teama {
    width: 80px;
    height: 80px;
    margin: 0px !important;
  }

  .str_bg .al_center {
    /* for the boxes : alligning text left */
    text-align: left !important;
  }

  #artificial-intelligence .mob_card .cardbx {
    display: flex !important;
    align-items: center !important;
    padding: 15px 25px !important;
  }

  #artificial-intelligence .bxicon {
    max-width: 60px !important;
    height: 60px !important;
    padding-top: 12px !important;
    margin-right: 12px !important;
  }

  .mob_card .boxbg {
    display: flex !important;
    align-items: center;
  }

  .boxbg .al_center {
    /* for the boxes : alligning text left */
    text-align: left !important;
  }

  #esg_news.insight_edge .boxbg .al_center {
    text-align: center !important;
  }

  .boxbg .allic {
    width: 50px !important;
    margin-bottom: 5px !important;
    margin-right: 10px !important;
  }

  .numcircle {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #ffffff;
    border: 3px solid #1930ab;
    display: grid !important;
    place-items: center !important;
  }

  .numcircle .MuiSvgIcon-root {
    font-size: 30px !important;
  }

  /* sba page  */
  .sbaprocess_boxbg_lg {
    height: auto !important;
    min-height: auto !important;
  }

  .sbaprocess_boxbg_md {
    height: auto !important;
    min-height: auto !important;
  }

  .sbaprocess_boxbg_sm {
    height: auto !important;
    min-height: auto !important;
  }

  #sba_solutions .boxbg {
    padding-top: 10px !important;
  }

  #sba_solutions .topbanner2 {
    max-width: 90% !important;
  }

  #esg_news .boxbg {
    padding-top: 10px !important;
  }

  #esg_news .topbanner2 {
    max-width: 90% !important;
  }

  .sbaprocess_boxbg {
    /* padding-top: 30px !important; */
    padding: 20px !important;
  }

  .sbaprocess_boxbg h6 {
    margin-bottom: 5px !important;
  }

  /* sba page  */

  #product-page .two {
    min-height: auto !important;
  }

  .cardbx {
    text-align: left !important;
    padding: 20px !important;
    display: flex !important;
    align-items: center !important;
  }

  .cardbx .bxicon {
    height: 50px !important;
    margin-right: 10px !important;
  }

  .cardbx .al_center {
    /* for the boxes : alligning text left */
    text-align: left !important;
  }

  #mui-table .MuiTableCell-body {
    font-size: 11px !important;
    padding: 4px 12px !important;
    line-height: 16px !important;
  }

  #mui-table .MuiTableCell-head {
    padding: 4px 12px !important;
    line-height: 16px !important;
    font-size: 11px !important;
  }

  .faq-image {
    display: none !important;
  }

  .dpabtn_sm1,
  .dpabtn_sm2 {
    padding: 5px 18px !important;
    font-size: 11px !important;
  }

  .stiky2 {
    padding: 6px 0px !important;
    margin-top: 6px !important;
  }

  #esg .stiky2 {
    margin-top: 0px !important;
  }

  .stiky2 .MuiContainer-root {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .reportbox {
    /* ESG Insights text box */
    margin-top: 20px !important;
    margin-bottom: 6px !important;
  }

  .thr {
    min-height: auto !important;
  }

  /* Footer CSS Started */
  .footerlink .menutt {
    font-size: 11px !important;
  }

  .footerlink .MuiSvgIcon-root {
    font-size: 16px !important;
  }

  .foottop2 .MuiButton-startIcon {
    width: 15px !important;
  }

  .foottop {
    padding: 15px 0px !important;
    padding-bottom: 2px !important;
  }

  .footerlink .MuiButton-startIcon img {
    height: 15px !important;
    width: 15px !important;
  }

  /* css added  5-11-23*/
  #new_landing_carousel .fdpalogo {
    width: 200px !important;
  }

  #new_landing_carousel .newfooterlink {
    display: grid !important;
    justify-items: center !important;
  }

  /* css added  5-11-23*/

  #article-page .whitebx {
    margin-top: 16px !important;
  }

  #article-page .whitebx.mt1 {
    margin-top: 8px !important;
  }

  #article-page .MuiIconButton-root {
    padding: 0px !important;
    margin-left: 3px !important;
    margin-top: 5px !important;
  }

  #article-page .MuiSvgIcon-root {
    font-size: 20px !important;
  }

  #case-studies .whitebx {
    margin-top: 16px !important;
  }

  #blog-page .whitebx {
    margin-top: 16px !important;
  }

  #blog-page h4 {
    text-align: left !important;
  }

  #blog-page .MuiIconButton-root {
    padding: 0px !important;
    margin-left: 3px !important;
    margin-top: 5px !important;
  }

  #blog-page .MuiSvgIcon-root {
    font-size: 20px !important;
  }

  .reporttitle_box {
    margin: 10px 0px !important;
    margin-bottom: 5px !important;
  }

  .reporttitle_box .reporttitle {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .reportinfo .reporttitle {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .reportinfo .reportm_bg .two {
    min-height: 43px !important;
  }

  .reporttitle_box .two {
    min-height: auto !important;
  }

  .reporttitle_box .thr {
    min-height: auto !important;
  }

  .reporttitle_box h6 {
    margin-bottom: 0px !important;
  }

  #blogs-tiles .reportm_bg h6 {
    margin-bottom: 2px !important;
  }

  .report_synopsis {
    margin-top: 10px !important;
  }

  .videback {
    height: 180px !important;
  }

  .maintabbedsection .MuiTab-root {
    margin-right: 8px !important;
    font-size: 11px !important;
    padding: 5px 22px !important;
  }

  .stiky {
    padding: 10px 0px;
  }

  .videobg {
    height: 210px !important;
  }

  #event-details .mainsmhead {
    text-align: left !important;
    font-size: 16px !important;
    line-height: 18px !important;
  }

  #event-details .whitebx {
    margin-top: 16px !important;
  }

  #client-testimonials .boxcontent {
    text-align: center !important;
    padding: 20px !important;
  }

  #client-testimonials .boxcontent h6 {
    text-align: center !important;
  }

  #partners-testimonials .boxcontent h6 {
    text-align: justify !important;
  }

  #partners-testimonials .boxcontent h4 {
    text-align: justify !important;
  }

  .award-details-section .topbanner {
    max-width: 200px !important;
  }

  .txtico {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .txt-iconbtn .MuiButton-iconSizeMedium .MuiSvgIcon-root {
    font-size: 16px !important;
  }

  .awardsindexcircle {
    height: 115px !important;
    width: 115px !important;
  }

  #artificial-intelligence .two {
    min-height: auto !important;
    height: auto !important;
  }

  #artificial-intelligence .thr {
    min-height: auto !important;
    height: auto !important;
  }

  #artificial-intelligence .solution_fx_bx {
    min-height: auto !important;
  }

  #artificial-intelligence .mob_center {
    text-align: center !important;
  }

  #esg_news .mob_center,
  #sba_solutions .mob_center {
    justify-content: center !important;
  }
}

.desk_view_only {
  display: block !important;
}

.mob_view_only {
  display: none !important;
}

.reso_bx h5 {
  font-size: 22px !important;
  line-height: 30px !important;
}

/* New Homepage Mobile Responsive CHeck */
@media (max-width: 959px) {
  .reso_bx .bl_overlay2 {
    background-color: rgba(25, 48, 171, 0.8) !important;
  }

  .reso_bx .skbl_overlay2 {
    background-color: rgba(0, 176, 240, 0.8) !important;
  }
}

@media (max-width: 600px) {
  .reso_bx .gry_overlay {
    background-color: #4d4f5da3 !important;
  }

  /* #new_landing_carousel .mainsection1 {
		background-image: url(asset/images/landing_page/mobile_banner_background.webp) !important;
		height: 45vh !important;
		place-items: center !important;
		padding-top: 50px !important;
		box-shadow: inset 0 0 0 1000px rgba(15, 2, 77, 0.61) !important;
	}

	#new_landing_carousel .mainsection1 .black3 {
		color: #fff !important;
	}

	#new_landing_carousel .mainsection1 .blue {
		color: #fff !important;
	} */

  .reso_bx .reso_img {
    height: 200px !important;
  }

  .reso_bx h5 {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .read_more {
    font-size: 11px !important;
  }

  .mob_left {
    text-align: left !important;
  }

  .mob_jst {
    text-align: justify !important;
  }

  .desk_view_only {
    display: none !important;
  }

  .mob_view_only {
    display: block !important;
  }

  /* mobile services section started */
  #services .services-card {
    display: flex !important;
    text-align: left;
    align-items: center !important;
    margin-top: 0px !important;
  }

  #services .img_box {
    margin-right: 15px !important;
  }

  #services .img_box,
  #services .service_img {
    height: 50px !important;
    width: 50px !important;
  }

  #services .flx_center {
    display: none !important;
  }

  /* products page css started */
  #products .product_bx .prod_img {
    background-image: none !important;
    height: auto !important;
    display: flex !important;
    align-items: center !important;
  }

  #products .prod_img .top {
    background-color: transparent !important;
    margin-right: 12px !important;
  }

  #products .prod_img .bottom {
    background-color: transparent !important;
    height: auto !important;
    color: #1930ab !important;
  }

  #products .prod_img .bottom .white {
    color: #1930ab !important;
  }

  #products .prod_img .bottom .subtxt {
    color: #4d4f5d !important;
  }

  .product_bx .pulse-button {
    background-color: transparent !important;
  }

  #products .pulse-button {
    -webkit-animation: smallPulse 1.25s infinite cubic-bezier(0.16, 0, 0, 1);
    -moz-animation: smallPulse 1.25s infinite cubic-bezier(0.16, 0, 0, 1);
    -ms-animation: smallPulse 1.25s infinite cubic-bezier(0.16, 0, 0, 1);
    animation: smallPulse 1.25s infinite cubic-bezier(0.16, 0, 0, 1);
  }

  .ourstrength .str_bg {
    font-size: 18px !important;
  }

  .reason .MuiFormControlLabel-root .MuiTypography-body1 {
    font-size: 12px !important;
  }

  .reason .MuiRadio-root {
    padding: 4px 9px !important;
  }
}

#new_innovation_design .innovation_bx {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important; */
  border-radius: 6px !important;
  /* cursor: pointer !important; */
  padding: 10px 13px !important;
  border-bottom: 3px solid #1930ab0d !important;
  background: #d2d2d21c !important;
}

.innovation_bg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  height: 180px !important;
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
  position: relative !important;

  display: grid !important;
  place-items: center !important;
}

.innovation_bg .pulse-button {
  height: 60px !important;
  width: 60px !important;
}

.innovation_bg .btn {
  bottom: 10px !important;
  position: absolute !important;
  right: 10px !important;
  pointer-events: none !important;
  /* background-color: #192fab90 !important; */
  background-color: #192fabce !important;

  color: #fff !important;
  font-size: 13px !important;
  padding: 4px 12px !important;
}

.innovation_content_bx {
  padding: 10px 0px !important;
  /* border-bottom: 1px solid #1930ab !important; */
}

.innovation_content_bx h5 {
  font-size: 20px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
  padding-bottom: 6px !important;
  padding-top: 6px !important;
}

/* .innovation_content_bx h6 {
	margin-bottom: 0px !important;
	display: -webkit-box !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	height: 52px !important;
} */

/* #new_innovation_design .subtxt {
	font-size: 13px !important;
	line-height: 20px !important;
	margin: 0px !important;
	padding: 0px !important;
	height: auto !important;
	margin-top: 10px !important;
} */

/* css added 08-12-23 */

.innovation_bx .MuiListItem-gutters,
.innovation_bx .MuiListItemButton-root {
  padding: 0px !important;
}

.innovation_bx .pdf_icon {
  width: 22px !important;
  height: auto !important;
}

.innovation_bx .web_icon {
  color: #1930ab !important;
  width: 27px !important;
}

.innovation_bx .list_btn .MuiTypography-body1 {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.innovation_bx .list_btn:hover,
.innovation_bx .list_btn:focus {
  background: transparent !important;
  box-shadow: none !important;
}

.innovation_content_bx h6 {
  margin-bottom: 0px !important;
  margin-top: 6px !important;
  /* height: 116px !important; */
}

.innovation_bx .list_text {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
}

.innovation_bx .synopsic_bx {
  padding: 4px 16px !important;
  background-color: transparent !important;
}

.innovation_bx .MuiList-padding {
  padding: 0px !important;
}

.innovation_bx .MuiListItemIcon-root {
  width: 25px !important;
  margin-right: 5px !important;
  min-width: auto !important;
}

/* new innovation page mobile view css started */

/* @media (max-width: 767px) {
	.innovation_content_bx h6 {
		height: 42px !important;
	}
} */

@media (max-width: 767px) {
  .innovation_content_bx h5 {
    font-size: 18px !important;
    line-height: 26px !important;
    font-weight: 600 !important;
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }
}

@media (max-width: 600px) {
  .innovation_inner_bx {
    display: flex !important;
    align-items: center !important;
  }

  .innovation_bg {
    background-image: none !important;
    height: auto !important;
    min-width: 55px !important;
  }

  .innovation_bg.d-none {
    display: none !important;
  }

  .innovation_content_bx {
    margin-left: 16px !important;
  }

  .innovation_content_bx h5 {
    font-size: 16px !important;
    line-height: 22px !important;
    height: auto !important;
  }

  .innovation_bx .list_btn .MuiTypography-body1 {
    font-size: 13px !important;
  }

  .innovation_content_bx h6 {
    margin-bottom: 0px !important;
    margin-top: 6px !important;
    height: auto !important;
  }

  .innovation_bx .pdf_icon {
    width: 20px !important;
    height: auto !important;
  }

  /* .innovation_bx .MuiDivider-root{
		margin-left: 17px !important;
	} */

  .innovation_bg .pulse-button {
    height: 30px !important;
    width: 30px !important;
    -webkit-animation: smallPulse 1.25s infinite cubic-bezier(0.16, 0, 0, 1);
    -moz-animation: smallPulse 1.25s infinite cubic-bezier(0.16, 0, 0, 1);
    -ms-animation: smallPulse 1.25s infinite cubic-bezier(0.16, 0, 0, 1);
    animation: smallPulse 1.25s infinite cubic-bezier(0.16, 0, 0, 1);
  }

  /* .innovation_content_bx h6 {
		overflow: visible !important;
		-webkit-line-clamp: inherit !important;
		display: block !important;
		-webkit-box-orient: inherit !important;
		height: auto !important;
	} */

  #faq-accordian .accordian_content,
  #faq-accordian .accordian_head {
    font-size: 13px !important;
    line-height: 22px !important;
  }
}

.synopsic_bx {
  background-color: #e3e8f061 !important;
  border-radius: 6px;
  padding: 20px;
}

#services.section {
  padding: 3% 0px;
  padding-bottom: 3% !important;
}

.lower {
  text-transform: lowercase !important;
}

#energy_tab .MuiTableCell-root {
  font-size: 10px !important;
  padding: 2px 6px !important;
  font-weight: 500 !important;
}

#energy_tab .MuiTableCell-stickyHeader {
  color: #1930ab;
  border-top: 1px solid #1930ab !important;
  border-bottom: 1px solid #1930ab !important;
}

#table_title h4 {
  font-size: 14px !important;
}

.align_c {
  display: flex !important;
  align-items: center !important;
}

.align_fs {
  display: flex !important;
  align-items: flex-start !important;
}

.table_text {
  font-size: 14px !important;
}

.w100 {
  width: 100% !important;
}

.contact_sales_icon {
  background-color: #00b0f0 !important;
  color: #fff !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  padding: 2px !important;
}

@media (max-width: 383px) {
  .w40 {
    width: 40% !important;
  }
}

@media (min-width: 600px) {
  .mb32 {
    margin-bottom: 32px !important;
  }
}

.d-icobtn {
  padding: 2px !important;
  margin-left: 8px !important;
}

.d-icobtn .MuiSvgIcon-root {
  font-size: 45px !important;
}

.fast_forward {
  animation: fast_forward 1s ease-in-out infinite !important;
}

@keyframes fast_forward {

  0%,
  100% {
    transform: translate(0px, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

.MuiTabScrollButton-root .MuiSvgIcon-root {
  color: #fff !important;
}

/* new css added for setting menus active */
.menutt.active_menu {
  font-weight: 500 !important;
  color: #00b0f0 !important;
}

.linkitem.active_drop_menu span {
  font-weight: 500 !important;
  color: #00b0f0 !important;
}

.py3 {
  padding: 3% 0px !important;
}

#esg_news .mnhg2 {
  min-height: 256px !important;
}

#esg-products .innovation_bx {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important; */
  border-radius: 6px !important;
  /* cursor: pointer !important; */
  padding: 10px 13px !important;
  border-bottom: 3px solid #1930ab0d !important;
  background: #fff !important;
}

.upload_btn {
  color: #1930ab !important;
  background-color: #fff !important;
  text-transform: none !important;
  font-size: 12px !important;
}

.flx_al_b {
  display: flex !important;
  align-items: baseline !important;
}

.flx_al_b h6 {
  margin-bottom: 0px !important;
}

.flx_al_b .upload_box {
  margin-left: 16px !important;
}

.awd_ttl {
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-bottom: 4px !important;
  color: #4d4f5d !important;
}

.cat_m h3 {
  font-size: 16px !important;
  line-height: 26px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  margin-bottom: 14px !important;
  text-transform: none !important;
  letter-spacing: 0.0075em !important;
}

/* .cat_m .mb0{
  margin-bottom: 0px !important;
} */

@media (max-width: 673px) {
  #esg-products .innovation_content_bx .thr {
    min-height: 60px !important;
  }
}

@media (max-width: 600px) {
  #esg_news .mnhg2 {
    height: auto !important;
    min-height: auto !important;
  }

  #esg-products .innovation_bg {
    min-width: auto !important;
  }

  .flx_al_b {
    display: block !important;
  }

  .flx_al_b .upload_box {
    margin-left: 0px !important;
  }

  .cat_m h3 {
    font-size: 13px !important;
    line-height: 22px !important;
  }
}

#top-trends .MuiListItem-root {
  padding: 8px 0px !important;
}

#top-trends .blog_icon {
  background-color: #1930ab !important;
  padding: 4px !important;
  width: 100% !important;
}

#top-trends .MuiListItemAvatar-root.main-icon {
  min-width: 48px !important;
}

#top-trends .MuiListItemAvatar-root.sub-icon {
  min-width: 40px !important;
}

#top-trends .MuiList-root.MuiList-padding {
  padding-left: 0px !important;
}

#top-trends .MuiAvatar-colorDefault {
  color: #1930ab !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}

#top-trends .sub-list .MuiListItem-root {
  padding: 0px !important;
}

.typo-start-icon {
  top: 2px !important;
  position: relative;
  margin-right: 6px !important;
}

.new_cs .boxbg {
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: none !important;
  border: 2px solid #a6a6a6 !important;
}

/* webinar */

#webinar h5 {
  font-size: 37px !important;
  line-height: 46px !important;
}

#webinar h6 {
  font-size: 14px !important;
  line-height: 24px !important;
}

#webteamsection h6 {
  font-size: 14px !important;
  line-height: 24px !important;
}

#webinar .h6bl {
  font-size: 18px !important;
  line-height: 26px !important;
}

#webinar .line {
  height: 3px !important;
  width: 130px !important;
  background-color: #1930ab !important;
}

#webinar .line2 {
  height: 4px !important;
  width: 100px !important;
  background-color: #1930ab !important;
  display: flex !important;
  justify-content: center !important;
  border-radius: 20px !important;
}

#webteamsection .wh_line {
  height: 4px !important;
  width: 100px !important;
  background-color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  border-radius: 20px !important;
  margin-bottom: 4px !important;
}

#webinar ul {
  padding-left: 16px !important;
}

#webinar ul li::marker {
  color: #4d4f5d !important;
}

#webinar ul h6 {
  margin-bottom: 6px !important;
}

#webteamsection {
  background-image: url(../src/asset/images/bgwebinar.webp);
  width: 100% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
}

#webteamsection .whitebx {
  background-color: #ffffff26;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 20px;
}

#webteamsection .teama {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin: 0 auto !important;
  background-size: cover;
  background-position: center top;
  filter: none !important;
  transition: none !important;
}

#webteamsection .thr {
  min-height: 70px !important;
}

.webinarregistration .whitebx {
  box-shadow: none !important;
  border: solid 1px #d2d2d2 !important;
  padding: 40px !important;
}

.bg_websection {
  background: linear-gradient(to bottom,
      #1930ab 16%,
      #0889dd 66%,
      #05d6c0b2 100%) !important;
  position: sticky !important;
  top: 20px !important;
  min-height: 100vh !important;
  padding-top: 20px !important;
  display: grid !important;
  place-content: center !important;
}

.datetimebx {
  border-top: 2px solid #a6a6a6 !important;
  border-bottom: 2px solid #a6a6a6 !important;
  display: flex !important;
  align-items: center !important;
  align-content: center !important;
}

.webinar-icon {
  font-size: 15px !important;
  /* margin-right: 2px !important; */
  /* top: 3px !important; */
  position: relative !important;
  color: #a6a6a6 !important;
}

.ai_center {
  align-items: center !important;
}

.web_highlightbx {
  background: rgb(5, 214, 192);
  background: -moz-linear-gradient(45deg,
      rgba(5, 214, 192, 1) 0%,
      rgba(8, 137, 221, 1) 75%);
  background: -webkit-linear-gradient(45deg,
      rgba(5, 214, 192, 1) 0%,
      rgba(8, 137, 221, 1) 75%);
  background: linear-gradient(45deg,
      rgba(5, 214, 192, 1) 0%,
      rgba(8, 137, 221, 1) 75%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#05d6c0", endColorstr="#0889dd", GradientType=1);
  padding: 25px 36px !important;
  border-radius: 8px !important;
  color: #fff !important;
  display: grid !important;
  place-items: center !important;
}

.webinar_who_attent {
  width: 100% !important;
  height: 290px !important;
  background-image: url(asset/images/webinar_who_attent.webp) !important;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 0px !important;
}

.web_heighlightbx {
  padding: 25px 0px !important;
}

.bg_highlights {
  background-color: #f5f5f5 !important;
}

@media (max-width: 1280px) and (min-width: 768px) {
  #webinar .section {
    padding: 10% 0px !important;
    padding-bottom: 5% !important;
  }
}

@media (max-width: 767px) {
  .grid-custom-12 {
    flex-basis: 100% !important;
    display: block !important;
    width: 100% !important;
    flex-grow: 1 !important;
    max-width: 100% !important;
  }
}

@media (max-width: 600px) {

  #webinar h6,
  #webteamsection h6 {
    font-size: 13px !important;
    line-height: 22px !important;
  }

  #webinar h5 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  #webinar .h6bl {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  #webinar ul h6 {
    margin-bottom: 4px !important;
  }

  #webinar .subhead {
    margin-top: 0px !important;
  }

  .mb16 {
    margin-bottom: 16px !important;
  }

  #webteamsection .al_left {
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
  }
}

@media (min-width: 600px) {
  #webinar .web_highlightbx {
    min-height: 122px !important;
  }
}

/* webinar landing pop-up */

#webinar .webinar_modal {
  padding: 0px !important;
}

#webinar .live_btn {
  background-color: #f00 !important;
  text-transform: capitalize !important;
  border-radius: 26px !important;
  padding: 4px 12px !important;
}

#webinar .web_popup_bg {
  background-image: url(../src/asset/images/web_popup_bg.webp);
  height: 280px !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  padding: 25px !important;
  background-color: rgba(25, 48, 171, 0.6) !important;
  background-blend-mode: soft-light !important;
  position: absolute !important;
  width: 100% !important;
}

#webinar .teama {
  width: 120px !important;
  height: 120px !important;
  filter: none !important;
}

#webinar .timer_box {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
  border-radius: 26px !important;
  margin: 0px 100px !important;
  position: relative !important;
}

#webinar .countdown {
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
  padding: 0px 8px !important;
  margin-bottom: 8px !important;
}

#webinar .webinar_modal h6 {
  font-size: 13px !important;
  line-height: 19px !important;
}

#webinar .left-brd {
  border-left: 1px solid #a6a6a6 !important;
  padding: 0px 8px !important;
}

#webinar .al_center .black3 {
  font-size: 22px !important;
  line-height: 32px !important;
  font-weight: 400 !important;
}

#webinar .alltxfieldlg .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid #a6a6a6 !important;
  border-radius: 6px !important;
}

#webinar .alltxfieldlg .MuiOutlinedInput-input {
  text-align: center !important;
}

#webinar .alltxfieldlg ::placeholder {
  text-align: center !important;
  font-weight: 600 !important;
}

#webinar .dot_img1 {
  width: 70px !important;
  position: absolute !important;
  top: 375px !important;
  left: 24px !important;
}

#webinar .dot_img2 {
  width: 70px !important;
  position: absolute !important;
  top: 375px !important;
  right: 24px !important;
}

/* newsroom */
#newsroom.stiky {
  position: sticky !important;
  top: 137px !important;
  background-color: #ebf2fc !important;
  padding: 20px 0px;
  font-size: 20px;
  z-index: +999 !important;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

#newsroom.section {
  padding: 3% !important;
}

.dgrid_center {
  display: grid !important;
  justify-content: center !important;
}

#newsroom.maintabbedsection.linkedtabs .MuiTab-root.Mui-selected {
  background-color: #1930ab !important;
  border-bottom: none !important;
  color: #fff !important;
}

#newsroom.maintabbedsection .MuiTab-root {
  width: auto !important;
  background: #a6a6a6 !important;
  margin: 0px 7px !important;
  color: #fff !important;
  font-size: 12px !important;
  min-height: auto !important;
  min-width: auto !important;
  text-transform: capitalize !important;
  position: relative;
  border-radius: 4px !important;
  padding: 8px 70px;
}

#newsroom .MuiTabScrollButton-root .MuiSvgIcon-root,
#newsletter .MuiTabScrollButton-root .MuiSvgIcon-root,
#Event .MuiTabScrollButton-root .MuiSvgIcon-root {
  color: #1930ab !important;
}

@media (max-width: 1280px) {
  #newsroom.section_mob {
    padding-top: 70px !important;
    padding-bottom: 25px !important;
  }
}

@media (max-width: 600px) {
  #newsroom.stiky {
    position: sticky !important;
    top: 100px !important;
    padding: 10px 0px !important;
  }
}

/* ESG Tabs */
/* #esg .stiky {
  position: sticky !important;
  top: 60px !important;
  background-color: #ebf2fc !important;
  padding: 20px 0px;
  font-size: 20px;
  z-index: +999 !important; 
} */



#esg .MuiTabScrollButton-root .MuiSvgIcon-root {
  color: #1930ab !important;
}

#esg .stiky2 {
  position: sticky !important;
  top: 135px !important;
  border-top: 4px solid #fff !important;
  z-index: +9 !important;
}

#esg .section {
  padding: 3% !important;
}

.maintabbedsection.stiky4.linkedtabs .MuiTab-root.Mui-selected {
  background-color: #1930ab !important;
  border-bottom: none !important;
  color: #fff !important;
}

#esg .maintabbedsection.linkedtabs .MuiTab-root.Mui-selected {
  background-color: #00b0f0 !important;
  border-bottom: none !important;
  color: #fff !important;
}

/* #esg .maintabbedsection .MuiTab-root {
  width: auto !important;
  background: #a6a6a6 !important;
  margin: 0px 7px !important;
  color: #fff !important;
  font-size: 12px !important;
  min-height: auto !important;
  min-width: auto !important;
  text-transform: capitalize !important;
  position: relative;
  border-radius: 4px !important;
  padding: 6px 30px;
} */

#esg .maintabbedsection2.linkedtabs .MuiTab-root.Mui-selected {
  color: #fff !important;
  background: #01b4f0 !important;
}

#esg .MuiAppBar-colorPrimary {
  background-color: transparent !important;
  box-shadow: none !important;
}

#esg .maintabbedsection2 .MuiTab-root {
  width: auto !important;
  background: #ffffff !important;
  margin-right: 14px !important;
  color: #85898d !important;
  font-size: 12px !important;
  min-height: auto !important;
  min-width: auto !important;
  text-transform: capitalize !important;
  position: relative;
  border-radius: 20px;
  padding: 8px 30px;
}

.stiky4 .MuiTab-root {
  padding: 6px 30px;
}

#esg.stiky3 {
  position: sticky !important;
  top: 60px !important;
  background-color: #ebf2fc !important;
  padding: 0px 0px !important;
  font-size: 20px;
  z-index: +999 !important;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.stiky4 {
  position: sticky !important;
  top: 133px !important;
  background-color: #d8d8d8 !important;
  padding: 20px !important;
  z-index: +99 !important;
}

#newsletter .stiky,
#Event .stiky {
  position: sticky !important;
  top: 60px !important;
  background-color: #ebf2fc !important;
  padding: 12px 0px !important;
  font-size: 20px !important;
  z-index: +999 !important;
  margin-bottom: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.mt16 {
  margin-top: 16px !important;
}

.mt40 {
  margin-top: 40px !important;
}

@media (max-width: 600px) {
  #esg .maintabbedsection .MuiTab-root {
    margin-right: 8px !important;
    font-size: 11px !important;
    padding: 5px 22px !important;
  }

  #esg.stiky3 {
    padding: 0px !important;
  }

  .stiky4 {
    padding: 10px !important;
    top: 115px !important;
  }

  #esg .stiky2,
  #esg-insights .stiky4 {
    top: 115px !important;
  }

  .mt16 {
    margin-top: 0px !important;
  }

  .mt40 {
    margin-top: 0px !important;
  }
}

@media (max-width: 1280px) {
  #esg .section_mob {
    padding-top: 70px !important;
    padding-bottom: 25px !important;
  }
}

@media (max-width: 600px) {
  #esg .stiky {
    padding: 10px 0px !important;
  }
}

/* whitepaper */
#article-page .article.section {
  padding: 15% 0% !important;
  padding-bottom: 3% !important;
}

#article-page .bg_websection {
  background: #ebf2fc !important;
  position: sticky !important;
  top: 20px !important;
  min-height: 100vh !important;
  padding-top: 100px !important;
  display: grid !important;
  place-content: start !important;
}

@media (max-width: 767px) {
  #article-page .bg_websection {
    min-height: auto !important;
    padding: 0px !important;
    margin-bottom: 0px !important;
  }

  #article-page .web_section.whitebx {
    margin-top: 0px !important;
  }
}

@media (max-width: 600px) {
  #article-page .article.section {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
}

#sm .teama {
  width: 110px !important;
  height: 110px !important;
  border-radius: 100%;
  margin: 0 auto;
  background-size: cover;
  background-position: center top;
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out;
}

#sm .teaminfo {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
  margin-bottom: 10px !important;
}

#sm h6 {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  margin-bottom: 8px !important;
}

.lh20 {
  line-height: 20px !important;
}

@media (max-width: 767px) {
  #sm .teama {
    width: 90px !important;
    height: 90px !important;
    border-radius: 100%;
    margin: 0 auto;
    background-size: cover;
    background-position: center top;
    filter: grayscale(100%);
    transition: filter 0.5s ease-in-out;
  }

  #sm .teaminfo {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    font-family: "Poppins", sans-serif !important;
    text-transform: capitalize;
    margin-bottom: 10px !important;
  }

  #sm h6 {
    font-size: 13px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    margin-bottom: 8px !important;
  }
}

.lblue {
  color: #1930ab57 !important;
}

.modal .sutrabox {
  box-shadow: none !important;
  height: auto !important;
  padding: 0px !important;
}

.modal .sutrabox img {
  margin-bottom: 16px !important;
}

.modal .sutrabox:hover {
  box-shadow: none !important;
}

.modal .sutrabox:hover .sutratxt {
  color: #d8d8d8 !important;
}

.sm_partner_logo {
  width: 20px !important;
}

.partner_section .MuiListItemIcon-root {
  min-width: 20px !important;
}

/* decimal mantra */
#mouse-over-popover.MuiPopover-root {
  max-width: 80% !important;
  /* top: 24px !important;
position: relative !important; */
}

.mantra {
  color: #00b0f0 !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.tooltip-mantra {
  background-color: #fff !important;
  max-width: 650px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.desktop-only,
.mainsection1.desktop-only {
  display: block !important;
}

.mobile-only,
.mainsection1.mobile-only {
  display: none !important;
}

.MuiTooltip-arrow {
  color: #fff !important;
}

.references_linkbx {
  background: #f4f4f4 !important;
  padding: 3px 14px !important;
  border-radius: 6px !important;
}

.references_linkbx h6 {
  font-size: 13px !important;
  line-height: 22px !important;
  margin-bottom: 6px !important;
}

.references_linkbx .heading {
  font-size: 16px !important;
  line-height: 26px !important;
  margin-bottom: 6px !important;
}

/* decimal mantra */

/* Current Openings */
.job_details h5 {
  font-size: 20px !important;
}

.job_details {
  background-color: #e8e8e8;
  border-radius: 6px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
  /* border: 2px solid #a6a6a6 !important; */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.designation_box {
  min-height: 55vh !important;
  overflow-y: auto !important;
  margin-top: 16px;
  overflow-x: hidden !important;
}

.no-jobs {
  width: 240px !important;
  height: auto !important;
}

.empty {
  /* background-color: aliceblue !important; */
  /* border-radius: 6px !important; */
  display: grid !important;
  place-items: center !important;
  text-align: center !important;
  user-select: none !important;
  min-height: 40vh !important;
}


.capabilities_card {
  background-color: #f2f2f2 !important;
  border-radius: 4px !important;
  text-align: center !important;
  padding: 30px 20px !important;
  /* padding-bottom: 10px !important; */
  position: relative !important;
  border: 1px solid transparent !important;
  transition: all 0.25s ease-in-out !important;
}

.capabilities_card img {
  width: 40px !important;
  height: auto !important;
}

.step-number {
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 6px !important;
  text-transform: uppercase;
  color: #b8b8b8 !important;
}


.capabilities_card:hover {
  border: 1px solid #1930ab !important;
  background-color: white !important;
}

.direction-arrow {
  background-color: #00b0f0 !important;
  border-radius: 50% !important;
  color: white !important;
  height: 50px !important;
  width: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: absolute !important;
  bottom: 10px !important;
  right: -30px !important;
  z-index: +8 !important;
}

.cloud_services.banner_bx .banner_img {
  height: auto !important;
}

.cloud_services.banner_bx .img_over {
  padding: 4% 0px !important;
}

.cloud_services.banner_bx .bl_overlay {
  /* background-color: #ebf2fc36 !important; */
  background-color: transparent !important;
  /* background-image: linear-gradient(120deg, #56ccf25e 28%, #2f80ed7a 100%) !important; */
  background-image: linear-gradient(120deg, #acb6e5a1 28%, #86fde882 100%) !important;
}

.cloud_services.banner_bx .bl_overlay1 {
  background-color: transparent !important;
  background-image: linear-gradient(120deg, #6190E863 28%, #A7BFE84D 100%) !important;
}

.cloud_services.banner_bx .bl_overlay2 {
  background-color: transparent !important;
  background-image: linear-gradient(120deg, #8CA6DB63 28%, #6A00BB36 100%) !important;
}

.cloud_services.banner_bx .bl_overlay3 {
  background-color: transparent !important;
  background-image: linear-gradient(90deg, rgba(25, 48, 171, 0.68) 28%, rgba(0, 176, 240, 0.45) 81%) !important;
}

.capabilities_card h6 {
  min-height: 110px !important;
}

@media (max-width: 1200px) {
  .capabilities_card h6 {
    min-height: 60px !important;
  }
}

@media (max-width: 767px) {
  .capabilities_card h6 {
    min-height: 45px !important;
  }

  .job_details h5 {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

@media (max-width: 600px) {
  .capabilities_card h6 {
    min-height: auto !important;
  }

  .direction-arrow {
    transform: rotate(90deg) !important;
    bottom: -30px !important;
    right: 0 !important;
    left: 0 !important;
    margin: auto !important;
  }

  .job_details {
    display: block;
    text-align: center !important;
  }

  .job_details .al_left,
  .job_details .al_right {
    text-align: center !important;
  }

  .clickup_features .desktop-only,
  .current_openings .desktop-only,
  #business_intelligence.desktop-only,
  .mainsection1 .desktop-only {
    display: none !important;
  }

  .clickup_features .mobile-only,
  #business_intelligence.mobile-only,
  .mainsection1.mobile-only {
    display: block !important;
  }


}

#sba_success_stories .sba_sucess_story {
  display: none !important;
}

#sba_success_stories .MuiContainer-root {
  padding: 0px !important;
}

#sba_success_stories .section {
  padding: 0px !important;
}

/* Current Openings */

.artical_cta_btn {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  margin: 0px !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize !important;
  color: #1930ab !important;
  background-color: transparent !important;
}

/* magazine css */
.magazine-iframe {
  height: auto !important;
  height: calc(100vh - 125px) !important;
  min-height: 575px !important;
}



.pdf_icon {
  width: 20px !important;
}

#title {
  display: none !important;
  visibility: hidden !important;
}

.read_btn {
  background-color: #1930ab !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding: 4px 12px !important;
}

.news25_date {
  background-Color: #ebf2fc !important;
  position: relative !important;
  z-Index: 8 !important;
  padding-Bottom: 1px !important;
}


/* fund management css started */
#fund_mngt .banner_img {
  height: 600px !important;
}

#fund_mngt h1 {
  font-size: 58px !important;
  line-height: 65px !important;
  text-shadow: 3px 3px 3px #fff !important;
}

#fund_mngt .dpabtn_sm1 {
  background-color: #1930ab !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 6px 20px !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}

#fund_mngt .allic {
  height: 70px !important;
  width: 70px !important
}

#fund_mngt .allic.dblue {
  background-color: #1930ab !important;
  border-radius: 12px !important;
  padding: 12px !important;
}

#fund_mngt .allic.sblue {
  background-color: #00b0f0 !important;
  border-radius: 12px !important;
  padding: 12px !important;
}

#fund_mngt .boxbg {
  border-radius: 20px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 25px;
}

#fund_mngt .mnhg5 {
  min-height: 432px !important;
}

#fund_mngt .topbanner5 {
  max-width: 55% !important;
}


/* fund management css end */
@media (max-width: 767px) {
  #fund_mngt h1 {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  #fund_mngt .banner_img {
    height: 400px !important;
  }
}

@media (max-width: 613px) {

  .magazine-iframe,
  #magazine.desktop-only {
    display: none !important;
  }

  .stay2 {
    font-size: 17px !important;
    color: #1930ab !important;
    line-height: 25px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif !important;
  }
}

@media (max-width: 599px) {

  #newsletter .linkedtabs .flx_fe,
  #newsletter .tabbedbar .MuiTabs-flexContainer {
    justify-content: center !important;
  }

  .news25_date h6 {
    padding-top: 16px !important
  }

  #fund_mngt h1 {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  #fund_mngt .mob_center {
    justify-content: center !important;
  }

  #fund_mngt .dpabtn_sm1 {
    padding: 5px 18px !important;
    font-size: 11px !important;
  }

  #fund_mngt .mnhg5 {
    height: auto !important;
    min-height: auto !important;
  }

  #fund_mngt .boxbg {
    padding: 12px 25px;
  }

  #fund_mngt .allic {
    height: 50px !important;
    width: 50px !important
  }
}