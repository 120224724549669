.ora_iconbx {
  height: 60px !important;
  display: grid !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
}

/* .ora_experties {
  padding: 15px !important;
  border-radius: 8px !important;
  transition: all 0.3s ease-in-out !important;
  min-height: 257px !important;
} */

.ora_experties {
  border-radius: 8px !important;
  min-height: 257px !important;
  padding: 15px !important;
  transition: all 0.3s ease-in-out !important;
  border: 3px solid #fff;
  background: #ffffff57;
}

.ora_iconbx .allic {
  width: 60px !important;
  background: #1930ab0f !important;
}

.ora_experties:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.3s ease-in-out !important;
  background-color: #fff !important;
}

.ora_title_btn {
  background-color: #00b0f0 !important;
  color: #fff !important;
  border-radius: 0px !important;
  border-top-right-radius: 26px !important;
  border-bottom-right-radius: 26px !important;
  padding: 6px 20px !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  position: relative;
  top: 20px !important;
}

.oraContact .topbanner2 {
  min-width: 100% !important;
  border-radius: 8px !important;
}

.ora_app {
  padding: 15px !important;
  border-radius: 8px !important;
  transition: all 0.3s ease-in-out !important;
}

.ora_app_iconbx {
  display: grid !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
}

.ora_app_iconbx .allic {
  width: 70px !important;
  background-color: #1930ab !important;
  border-radius: 50%;
  padding: 8px !important;
}

.ora_red {
  color: #c74634 !important;
}

.ibm_experties .ora_iconbx {
  height: 60px !important;
  display: grid !important;
  align-items: center !important;
  justify-content: left !important;
  border-radius: 6px !important;
}

.ibm_experties {
  border-radius: 8px !important;
  min-height: 257px !important;
  padding: 15px !important;
  transition: all 0.3s ease-in-out !important;
  border: 3px solid #fff;
  background: #ffffff57;
}

.ibm_experties:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.3s ease-in-out !important;
  background-color: #fff !important;
}

.ibm_experties .ora_iconbx .allic {
  background-color: #1930ab !important;
  border-radius: 50% !important;
}

.ibm_textbx {
  position: relative !important;
}

.ibm_textimg1 {
  position: absolute !important;
  /* left: -209px !important;
  top: -233px !important; */
  margin-top: -300px !important;
  left: -200px !important;
  opacity: 0.5 !important;
}

.ibm_textimg2 {
  position: absolute !important;
  /* right: -209px !important;
  top: -22px !important; */
  margin-top: -50px !important;
  right: -209px !important;
  opacity: 0.5 !important;
}

.ibmrelative {
  position: relative !important;
}

.dblack {
  color: #000 !important;
}

.dblack.fw7 {
  font-weight: 700 !important;
}

.ibmtitle_blue {
  color: #1365ff !important;
}

.ibmtitle_blue sup {
  font-size: 8px !important;
  top: -4px !important;
  left: 2px !important;
  position: relative;
  font-weight: 500 !important;
}

.aboutibm sup {
  font-size: 7px !important;
}

.clickup_page .ibm_experties {
  border-radius: 8px !important;
  min-height: 300px !important;
  padding: 15px !important;
  transition: all 0.3s ease-in-out !important;
  border: 3px solid #fff;
  background: #ffffff57;
}

@media (max-width: 959px) {
  .ora_experties .thr {
    min-height: 50px !important;
  }
}

@media (max-width: 600px) {
  .ora_experties {
    min-height: auto !important;
  }

  .ora_iconbx {
    height: 50px !important;
    display: grid !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 6px !important;
  }

  .ora_iconbx .allic {
    width: 50px !important;
  }

  .ora_experties .thr {
    min-height: auto !important;
  }

  .ora_title_btn {
    padding: 4px 20px !important;
    font-size: 12px !important;
  }

  .ibm_textimg1 {
    display: none !important;
  }

  .ibm_textimg2 {
    display: none !important;
  }

  .ibmtitle_blue sup {
    font-size: 6px !important;
  }

  .aboutibm sup {
    font-size: 6px !important;
  }

  .ibm_experties {
    border-radius: 8px !important;
    min-height: auto !important;
    padding: 15px !important;
    transition: all 0.3s ease-in-out !important;
    border: 3px solid #fff;
    background: #ffffff57;
  }

  .clickup_page .ibm_experties {
    min-height: auto !important;
  }

  .clickup_page .ibm_experties.boxbg {
    display: flex !important;
    align-items: center !important;
  }

  .clickup_page .ibm_experties .ora_iconbx {
    padding-top: 0px !important;
  }



  .ibm_experties.boxbg {
    display: flex !important;
    align-items: flex-start !important;
  }

  .ibm_experties .ora_iconbx {
    padding-top: 20px !important;
  }

}

#new_landing_carousel.ibmbanner .mainsection1 {
  place-items: center !important;
  padding-top: 165px !important;
}